import "./profile.scss";
import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
// import { useNavigate } from "react-router-dom";
import api from "../../redux/APIS/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
const { REACT_APP_API_BASEURL } = process.env;

const Profile = () => {
  const navigate = useNavigate()
  const { Id } = useParams()
  const [user, setUser] = useState({})
  // const navigate=useNavigate()

  const loadProfile = async () => {
    if (Id > 0) {
      const res = await api.get(`/resources/main/${Id}`)
        .then().catch(error => console.log(error))
      if (res && res.data && res.data.data) {
        setUser(res.data.data);
      }
    } else {
      const res = await api.get('/user')
        .then().catch(error => console.log(error))
      if (res && res.data && res.data.data) {
        setUser(res.data.data);
      }
    }
  }

  const onEdit = () => {
    // console.log(user);
    navigate("/edit-profile", { state: user })
  }

  useEffect(() => {
    loadProfile()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">

            <div className="item">
              {user.candidate_profile_image && user.candidate_profile_image.length > 0 ?
                <img src={(REACT_APP_API_BASEURL).replace("api/", "api") + user.candidate_profile_image[0].api_path_for_download}
                  alt="" className="itemImg" />
                :
                <img src="https://images.unsplash.com/photo-1504376379689-8d54347b26c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=536&q=80"
                  alt="" className="itemImg" />
              }
            </div>
          </div>
          <div className="right">
            {/* <Link to={"/edit-profile"} className="editButton">Edit</Link> */}

            <Tooltip title="Back">
              <button className="editButton" onClick={() => navigate(-1)}><ArrowBackIcon /></button>
            </Tooltip>
            <h1 className="title">Information
              {user.tms_role && (user.tms_role.role_key === "candidate" || user.tms_role.role_key === "evaluator") && <Tooltip title="Edit Profile"><Edit style={{ color: '#0052cc' }} onClick={() => onEdit()} /></Tooltip>}</h1>
            <div className="details">
              {user.first_name &&
                <div className="detailItem">
                  <span className="itemKey">Name :</span>
                  <span className="itemKey2">{user.first_name} {user.last_name && user.last_name}</span>
                </div>
              }
              {user.email &&
                <div className="detailItem">
                  <span className="itemKey">Email :</span>
                  <span className="itemKey2">{user.email}</span>
                </div>
              }
              {user.contact_no &&
                <div className="detailItem">
                  <span className="itemKey">Contact no. :</span>
                  <span className="itemKey2">{user.contact_no}</span>
                </div>
              }
              {user.date_of_birth &&
                <div className="detailItem">
                  <span className="itemKey">Date of Birth :</span>
                  <span className="itemKey2"> {user.date_of_birth}</span>
                </div>
              }
              {user.tms_role && user.tms_role.role_name &&
                <div className="detailItem">
                  <span className="itemKey">Role :</span>
                  <span className="itemKey2">{user.tms_role.role_name}</span>
                </div>
              }
              {user.department && user.department.name &&
                <div className="detailItem">
                  <span className="itemKey">Department :</span>
                  <span className="itemKey2">{user.department.name}</span>
                </div>
              }
              {user.address &&
                <div className="detailItem">
                  <span className="itemKey">Address :</span>
                  <span className="itemKey2"> {user.address}</span>
                </div>
              }

              {user.city && user.city.city_name &&
                <div className="detailItem">
                  <span className="itemKey">City :</span>
                  <span className="itemKey2">{user.city.city_name}</span>
                </div>
              }
              {user.state && user.state.name &&
                <div className="detailItem">
                  <span className="itemKey">State :</span>
                  <span className="itemKey2">{user.state.name}</span>
                </div>
              }
              {user.country && user.country.name &&
                <div className="detailItem">
                  <span className="itemKey">Country :</span>
                  <span className="itemKey2">{user.country.name}</span>
                </div>
              }
              {user &&
                <div className="detailItem">
                  <span className="itemKey">Is Fresher :</span>
                  <span className="itemKey2">{user.is_fresher === false ? "No" : "Yes"}</span>
                </div>
              }
              {user.candidate_qualification &&
                <div className="detailItem" style={{ display: "-webkit-inline-box" }}>
                  <span className="itemKey" style={{ float: "left" }}>Qualification :</span>
                  <span className="itemKey2" style={{ float: "left" }}>
                    {user.candidate_qualification.length > 0 ? user.candidate_qualification.map((e, index) => `${index ? ', ' : ''}${(e.qualification).trim()}`) : "N/A"}
                  </span>
                </div>
              }
              <br />
              {user.candidate_technologies &&
                <div className="detailItem" style={{ display: "-webkit-inline-box" }}>
                  <span className="itemKey" style={{ float: "left" }}>Technology :</span>
                  <span className="itemKey2" style={{ float: "left" }} >
                    {user.candidate_technologies.length > 0 ? user.candidate_technologies.map((e, index) => `${index ? ', ' : ''}${(e.technology_name).trim()}`) : "N/A"}
                  </span>
                </div>
              }
              {user.total_experience_in_months ?
                <div className="detailItem">
                  <span className="itemKey" >Experience in month :</span>
                  <span className="itemKey2" > &nbsp;&nbsp;&nbsp;{user.total_experience_in_months}</span>
                </div> : ""
              }
              <br />
              {user.candidate_documents &&
                <div className="detailItem" style={{ display: "-webkit-inline-box" }}>
                  <span className="itemKey" style={{ float: "left" }}>Documents Link :</span>
                  <span className="itemKey2" style={{ float: "left" }}>
                    {user.candidate_documents.length > 0 ? user.candidate_documents.map((e, index) => {
                      return (
                        <Link to={(REACT_APP_API_BASEURL).replace("api/", "api") + e.api_path_for_download} target={"_blank"} >

                          {index ? ' , ' : ''}{(e.display_name).trim().toUpperCase().replace("_", " ")}</Link>
                      )
                    }) : "N/A"}
                  </span>
                </div>
              }



            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Profile