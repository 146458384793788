import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./resource.scss";
import { candidateViewById } from "../../redux/features/resourceSlice";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CandidateView = () => {
    const dispatch = useDispatch()
    const { resourceid } = useParams()

    const { view_data , status, error } = useSelector(state => state.resource)

    useEffect(() => {
        if (resourceid) {
            dispatch(candidateViewById(resourceid))
        }
    }, [dispatch])
    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>Candidate View</h3>
                   
                    <Tooltip title="Back">
                        <Link to={'/candidate'} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                    {status === STATUSES.SUCCESS ?
                    <div className="view-details-row">
                        <div className="detailItem">
                            <span className="itemKey">Name :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.user && view_data.data.user.name}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Email :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.email}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Contact Number :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.contact_no}</span>
                        </div>

                        {/* <div className="detailItem">
                            <span className="itemKey">Gender :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.gender}</span>
                        </div> */}

                        {/* <div className="detailItem">
                            <span className="itemKey">Address :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.address}</span>
                        </div> */}
                    </div>
                    :
                    status === STATUSES.FAILURE  ?
                    <div>{error.message}</div>
                    :
                    <LoaderForm/>
                }
            </div>
        </div >
    )

}

export default CandidateView