const CONSTANTS = {
    COMMON_BUTTONS : {
        ADD:'Add',
        EDIT:'Edit',
        VIEW:'View',
        UPDATE:'Update',
        DELETE:'Delete',
        CREATE:'Create',
        SUBMIT:'Submit',
        CLOSE:'Close',
    },
    RESOURCE: {
        LIST: 'Candidate List',
        ADD: 'Add New',
        EDIT: 'Edit',
        VIEW: 'View',
        DELETE: 'Delete'
    },

}


export default CONSTANTS;