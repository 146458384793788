import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

// role based menu list sidebar
export const getMenuListForSideBar = createAsyncThunk("get/role-menu-list-sidebar", async (data) => {
    const res = await dataService.getRoleMenu(data);
    const resp = res.data;
    return resp;
});

const sidebarSlice = createSlice({
    name: "sidebar",
    initialState: {
        status: 'idle',
        error: '',
        sidebarMenuList: []
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getMenuListForSideBar.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMenuListForSideBar.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleMenuSidebarList`;
                let menu = [];
                let result = action.payload;
                let res = result && result.data && result.data.rows

                res.map(e => {
                    if (!e.parent_menu_id && e?.menu?.status === true) {
                         menu.push({
                            ...e,
                            link: e.menu.url_slug,
                            title: e.menu.title,
                            icon: e.menu.class_name,
                            sort_by: e.menu.sort_by,
                            // eslint-disable-next-line
                            submenu: res.map((ele) => {
                                if (ele.parent_menu_id === e.menu_id && ele?.menu?.status === true) {
                                    return ({
                                        ...ele,
                                        link: ele.menu.url_slug,
                                        title: ele.menu.title,
                                        icon: ele.menu.class_name,
                                        sort_by: ele.menu.sort_by,
                                        // eslint-disable-next-line
                                        menuItem: res.map((menu_item) => {
                                            if (menu_item.parent_menu_id === ele.menu_id && menu_item?.menu?.status === true) {
                                                return ({
                                                    ...menu_item,
                                                    link: menu_item.menu.url_slug,
                                                    sort_by: menu_item.menu.sort_by,
                                                    title: menu_item.menu.title,
                                                    icon: menu_item.menu.class_name,
                                                })
                                            }
                                        }).filter((item1) => item1 !== undefined)
                                    })
                                }
                            }).filter((item) => item !== undefined)
                        })
                    }
                    return menu
                })
                // console.log(menu, "menu")
                const sortingMenu = menu.length > 0 && menu.sort((a, b) => {
                    if (a.sort_by < b.sort_by)
                        return -1;
                    if (a.sort_by > b.sort_by)
                        return 1;
                    return 0;
                })
                state.sidebarMenuList = sortingMenu.length > 0 ? sortingMenu : action.payload;
            })
            .addCase(getMenuListForSideBar.rejected, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.error = action.error
            })
    },
});


export default sidebarSlice.reducer;