import React, { useEffect, useState } from 'react'

import "./register.scss";
import Logo from "../../images/logo.png"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../redux/features/authSlice";
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from '../../components/common-function/LoaderForm';
import { STATUSES } from '../../redux/common-status/constant';

const formErrData = Object.freeze({
  firstNameErr: "First Name is required",
  lastNameErr: "Last Name is required",
  contactNumberErr: "Contact Number is required",
  emailErr: "Email is required",
  passwordErr: "Password is required"
});

const Register = () => {
  const { REACT_APP_ENV } = process.env;

  const dispatch = useDispatch();
  const { registerUser, status, registerErr } = useSelector((state) => state.authUser)
  // const { status } = useSelector((state) => state.authRegister)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors] = useState(formErrData);
  const [loader, setLoader] = useState(false)
  const [errStatus, setErrStatus] = useState(false);
  const [validateErr, setValidateErr] = useState("")

  const navigate = useNavigate();
  // validate section
  const validate = (email, contact) => {
    const errors = {}

    if (!email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = 'Please enter valid email address'
    } else {
      errors.email = true
    }

    var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
    if (!pattern.test(contact)) {
      errors.contact = "Please enter number only";
    } else if (contact.length !== 10) {
      errors.contact = "Please enter valid contact number";
    } else {
      errors.contact = true
    }
    return errors
  }

  // submit section
  const HandleRegister = (e) => {
    e.preventDefault();
    if (firstName === "" || lastName === "" || contactNumber === "" || email === "" || password === "") {
      setErrStatus(true)
      setValidateErr("")
    }

    if (firstName !== "" && lastName !== "" && contactNumber !== "" && email !== "" && password !== "") {
      setErrStatus(false)
      const isValidate = validate(email, contactNumber)
      if (isValidate.email === true && isValidate.contact === true) {
        const data = {
          first_name: firstName,
          last_name: lastName,
          contact_no: contactNumber,
          email: email,
          password: password,
          resume_file: null,
          status: true
        }
        setValidateErr("")
        setLoader(true)
        dispatch(register(data));

      } else {
        setErrStatus(true)
        setValidateErr(isValidate)
      }
    }
  }

  // error handling
  if (loader === true && status === STATUSES.FAILURE && registerErr && registerErr.message) {
    setLoader(false)
    toast.error(registerErr.message);
  }

  // register 
  if (loader === true && status === `${STATUSES.SUCCESS}_register` && registerUser && registerUser.message) {
    if (registerUser.status === 200) {
      toast.success(registerUser.message);
      setTimeout(() => {
        navigate('/public/candidate-registration/verify', { state: registerUser.data })
      }, 2000)
    } else {
      toast.error(registerUser.message)
    }
    setLoader(false)
  }

  useEffect(() => {
    const user = localStorage.getItem(`${REACT_APP_ENV}_CES`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))
    if (user && user.token) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="register">
      <div className="sign-in-row">
        <div className="signin-col-1">
          <img src={Logo} alt={Logo} className="left-logo-icon" />
        </div>
        <div className="signin-col-2">
          <img src={Logo} alt={Logo} />
          <h4>Candidate Registration</h4>
          <ToastContainer />
          {loader && <LoaderForm />}
          <form onSubmit={HandleRegister}>
            <div className="formInput">
              <label> First Name</label>
              <input
                type="text"
                name="first_name"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {errStatus === true && formErrors && formErrors.firstNameErr && firstName === "" && <p className='error-message'>{formErrors.firstNameErr}</p>}
            </div>
            <div className="formInput">
              <label>Last Name</label>
              <input
                type="text"
                name="last_name"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {errStatus === true && formErrors && formErrors.lastNameErr && lastName === "" && <p className='error-message'>{formErrors.lastNameErr}</p>}
            </div>

            <div className="formInput">
              <label>Contact Number</label>
              <input
                type="text"
                name="contact_number"
                placeholder="Contact Number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
              {errStatus === true && validateErr === "" && formErrors && formErrors.contactNumberErr && contactNumber === "" && <p className='error-message'>{formErrors.contactNumberErr}</p>}
              {errStatus === true && validateErr && validateErr.contact !== true && <p className='error-message'>{validateErr && validateErr.contact}</p>}
            </div>

            <div className="formInput">
              <label>Email Address</label>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errStatus === true && validateErr === "" && formErrors.emailErr && email === "" && <p className='error-message'>{formErrors.emailErr}</p>}
              {errStatus === true && validateErr && validateErr.email !== true && <p className='error-message'>{validateErr && validateErr.email}</p>}

            </div>

            <div className="formInput">
              <label>Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errStatus === true && formErrors && formErrors.passwordErr && password === "" && <p className='error-message'>{formErrors.passwordErr}</p>}
            </div>

            <button
              type="submit"
              style={{ width: "100%" }}
              className={loader === true ? "submit-btn-disabled" : "submit-modal"}
              disabled={loader === true ? true : false}
            >Register</button>
            {/* link for login */}
            <p>Already have an account ?
              <Link to={"/"} className="forgot-password-link" style={{ display: "inline-block", marginTop: "15px" }}> Login</Link></p>

          </form>
        </div>
      </div>
    </div>
  )
}

export default Register
