import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from './features/authSlice';
import LogoutReducer from './features/authLogoutSlice';
import UiReducer from './features/uislice';
import resourceReducer from "./features/resourceSlice";
import departmentReducer from "./features/departmentSlice";
import roleReducer from "./features/roleSlice";
import userTypeReducer from "./features/userTypeSlice";
import dashboardCardSlice from "./features/dashboardSlice";
import masterReducer from "./features/masterSlice";
import thirdPartyReducer from "./features/thirdPartySlice";
import sidebarReducer from "./features/sidebarSlice";
import optionsReducer from "./features/optionsSlice";
import candidateExamReducer from "./features/candidateExamSlice";
import candidateExamListSlice from "./features/candidateExamListSlice";
import evaluationSlice from "./features/evaluationSlice";

const store = configureStore({
    reducer: {
        authUser: AuthReducer,
        logout: LogoutReducer,
        navbartoggle: UiReducer,
        changeCurrentMenu: UiReducer,
        resource: resourceReducer,
        department: departmentReducer,
        role: roleReducer,
        userType: userTypeReducer,
        dashboardCard: dashboardCardSlice,
        master: masterReducer,
        thirdParty: thirdPartyReducer,
        sidebar: sidebarReducer,
        dropDownOption: optionsReducer,
        candidate: candidateExamReducer,
        candidateExamListSlice: candidateExamListSlice,
        evaluationSlice: evaluationSlice,

    },
})
export default store;