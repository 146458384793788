import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

// get pending evaluation exam list
export const getEvaluationExamList = createAsyncThunk("get/pending-evaulation-exam-list", async (data) => {
    const res = await dataService.all_pending_evaluation_exam_list(data);
    const resp = res.data;
    return resp;
});

// get question details by candidate exam list
export const getQusetionDetailsList = createAsyncThunk("get/qusetion-details-by-candidate-exam", async (data) => {
    const res = await dataService.question_details_exam_list(data);
    const resp = res.data;
    return resp;
});

// get program by id
export const getProgramsById = createAsyncThunk("get/program-by-id", async (data) => {
    const res = await dataService.check_program_by_id(data);
    const resp = res.data;
    return resp;
});

// save program data
export const postProgarmData = createAsyncThunk("post/program-data", async (data) => {
    if (data === 'type') {
        return [];
    }
    const res = await dataService.save_program_data(data.ID, data.addvalues);
    const resp = res.data;
    return resp;
});

//candidate evalution finel submit
// save program data
export const candidateFinalEvalutorSubmit = createAsyncThunk("post/candidateFinalEvalutorSubmitData", async (data) => {
    const res = await dataService.evaluator_result_final_submition(data);
    const resp = res.data;
    return resp;
});

const evaluationSlice = createSlice({
    name: "evaluationSlice",
    initialState: {
        status: STATUSES.IDLE,
        error: '',
        evaluationExam_List: [],
        questionDetailsExam_List: [],
        program_Data: [],
        program_data_Save: [],
        final_submit_Exam: [],

    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get evaluation list 
            .addCase(getEvaluationExamList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(getEvaluationExamList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.evaluationExam_List = action.payload;
                state.error = ""
            })
            .addCase(getEvaluationExamList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get qusetion details by exam id
            .addCase(getQusetionDetailsList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(getQusetionDetailsList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionDetailsExam_List = action.payload;
                state.error = ""
            })
            .addCase(getQusetionDetailsList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get program data by id
            .addCase(getProgramsById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(getProgramsById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_program_data`;
                state.program_Data = action.payload;
                state.error = ""
            })
            .addCase(getProgramsById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //save evaluation prgram data(mark and remark)
            .addCase(postProgarmData.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(postProgarmData.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_program_data_Save`;
                state.program_data_Save = action.payload;
                state.error = ""
            })
            .addCase(postProgarmData.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //candidate evalution finel submit
            .addCase(candidateFinalEvalutorSubmit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(candidateFinalEvalutorSubmit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_final_submit_Exam`;
                state.final_submit_Exam = action.payload;
                state.error = ""
            })
            .addCase(candidateFinalEvalutorSubmit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

    },
});


export default evaluationSlice.reducer;