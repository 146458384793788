export const DefaultFilterColumns = {
    //list columns
    QUESTION_DETAILS_LIST_COLUMNS: ['"id"', '"evaluation_status"', '"status"'],

    //drop down columns

    CANDIDATE_DROUPDOWN_COLUMNS: ['"id"', '"first_name"', '"total_experience_in_months"', '"email"'],

    //json list columns


    //json dropdown columns
    DEPARTMENT_LIST_COLUMNS: ['"id"', '"code"', '"description"', '"name"', '"status"'],


}