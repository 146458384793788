import { Close, ZoomInMap, ZoomOutMap } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { CopyBlock, Snippet } from 'react-code-blocks';

function EvaluateDetails({ hideDiv, activeDivs, setActiveClass, activeClass, rowId, formValues }) {

    // const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (rowId && activeDivs === `story_${rowId}`) {
            // setLoading(true)
        }
        // eslint-disable-next-line
    }, [activeDivs]);

    return (
        <div className="ss-datatable style-table" style={{ marginTop: '32px' }}>
            {/* button secton start*/}

            <div className="row">
                <div className="col ss-top-icon" style={{ textAlign: "end" }}>
                    {<Tooltip title="Close">
                        <Button
                            style={{
                                marginTop: "3px",
                                backgroundColor: "rgb(201 201 201)",
                                color: "rgb(110 110 110)"
                            }}
                            onClick={() => hideDiv(false)}
                        >
                            <Close />
                        </Button>
                    </Tooltip>}


                    {!activeClass === true ? (
                        <Tooltip title="Zoom Out">
                            <Button
                                style={{
                                    marginTop: "3px",
                                    backgroundColor: "rgb(201 201 201)",
                                    color: "rgb(110 110 110)"
                                }}
                                onClick={() => setActiveClass(!activeClass)}
                            >
                                <ZoomOutMap />
                            </Button>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Zoom In">
                            <Button
                                style={{
                                    marginTop: "3px",
                                    backgroundColor: "rgb(201 201 201)",
                                    color: "rgb(110 110 110)"
                                }}
                                onClick={() => setActiveClass(!activeClass)}
                            >
                                <ZoomInMap />
                            </Button>
                        </Tooltip>
                    )}
                </div>

            </div>

            {/* button secton end*/}

            <div className="top-headings">
                <div>
                    <button style={{ borderWidth: 0, backgroundColor: "#fff" }}>
                        <h3 className="story-epic-summary">
                            {formValues && formValues.question && formValues.question.technology && formValues.question.technology.name}
                        </h3>
                    </button>
                </div>
            </div>
            {/* {loading === true && <LoaderForm />} */}

            <div className="view-page" style={{ padding: "18px" }}>
                <h5>Details</h5>
                <form style={{ boxShadow: "unset", margin: "unset", padding: "unset" }}>
                    <div className="add-new-user-form">
                        <div className="formInput" style={{ flexBasis: "20%" }}>
                            <label> Marks ({formValues && formValues.question && formValues.question.marks})</label>
                            <input
                                name="obtain_mark"
                                max={formValues && formValues.marks}
                                min={0}
                                type="number"
                                value={formValues && formValues.obtain_mark}
                                readOnly
                            />
                            {/* {errStatus === true && finalData.obtain_mark === "" && <p style={{ color: "#96332c", fontSize: 13 }}> Mark is requied</p>} */}
                        </div>
                        <div className="formInput" style={{ flexBasis: "100%" }}>
                            <label >  Remark(optional)  </label>
                            <textarea
                                name="evaluator_remark"
                                rows={2}
                                value={formValues && formValues.evaluator_remark}
                                readOnly
                            />
                        </div>
                    </div>
                </form>
                <div className="edit-details-row ss-detail-row">
                    <div className="m-2 h-14 border-2 border-black mx-auto">
                        <div className="wrapper">
                            <div className="question-wrapper">
                                <strong>Question : </strong>{" "}
                                <CopyBlock
                                    language="java"
                                    text={formValues && formValues.question && formValues.question.title ? formValues.question.title : ""}
                                    codeBlock
                                    theme={Snippet}
                                    showLineNumbers={false}
                                />
                            </div>
                            <p>
                                <strong>Answer : </strong>{" "}
                                <br />
                                {formValues && formValues.output_data && formValues.output_data.output}
                            </p>
                            <p></p>

                            <div className="main" style={{ marginBottom: "200px" }}>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EvaluateDetails