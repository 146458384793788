const { REACT_APP_ENV } = process.env;
class LocalStorageDataService {

    userDetail() {
        return localStorage.getItem(`${REACT_APP_ENV}_CES`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`)).user.data : "";
    }

    userToken() {
        return localStorage.getItem(`${REACT_APP_ENV}_CES`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`)).token : "";
    }

    lastInteractionTime() {
        return localStorage.getItem(`${REACT_APP_ENV}_CES`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`)).lastInteractionTime : "";
    }

    get_default_month_year() {
        return ["Months", "Years"]
    }

    schedule_exam_status() {
        // { value: 1, label: "Pending" }
        return [{ value: 4, label: "Pending" }, { value: 2, label: "Inprogress" }, { value: 3, label: "Completed" }, { value: 4, label: "Cancelled" }]
    }

    schedule_exam_status_new() {
        return [
            { value: 1, label: "Pending" },
            { value: 2, label: "In progress" },
            { value: 3, label: "Evaluation pending" },
            { value: 4, label: "Cancelled" },
            { value: 5, label: "Evaluator assigned" },
            { value: 6, label: "Evaluation in-progress" },
            { value: 7, label: "Result declared" },
        ]
    }

}
// eslint-disable-next-line
export default new LocalStorageDataService();