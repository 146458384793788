import Editor from '@monaco-editor/react';
import { Close, ZoomInMap, ZoomOutMap } from '@mui/icons-material';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { CopyBlock, Snippet } from 'react-code-blocks';
import { getProgramsById, postProgarmData } from '../../redux/features/evaluationSlice';
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from '../../redux/common-status/constant';
import { toast } from 'react-toastify';
import LoaderForm from '../../components/common-function/LoaderForm';
import dataService from '../../redux/services/data.service';
import { useParams } from 'react-router-dom';

const initialFormData = Object.freeze({
    obtain_mark: "",
    evaluator_remark: ""
});

function EvaluatePage({ hideDiv, activeDivs, setActiveClass, activeClass, rowId, listData, filterQuery, apiCallList }) {
    const dispatch = useDispatch()
    const { status, program_Data, program_data_Save } = useSelector((state) => state.evaluationSlice);
    const { id } = useParams()
    const [userOutput, setUserOutput] = useState("");
    const [userCode, setUserCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [finalData, setFinalData] = useState(initialFormData);
    const [reload, setReload] = useState(false);
    const [errStatus, setErrStatus] = useState(false);

    const apiCall = async (data, type) => {
        const res = await dataService.exam_compile_post(data)
        const result = await res.data
        setLoading(false);
        let userOutputis = ""
        if (result.data) {
            userOutputis = result.data.output_data.output
        } else {
            userOutputis = result.message
        }
        setTimeout(setUserOutput(userOutputis), 500);
    }

    // Function to call the compile endpoint
    function compile(type) {
        let code = formValues && formValues.file_content ? formValues.file_content : userCode
        if (code === ``) {
            return;
        }
        let data = {
            "candidate": Number(listData.candidate_id),
            "candidate_exam_schedule": Number(id),
            "question": formValues && formValues.question && formValues.question.id,
            "technology_key": formValues && formValues.question && formValues.question.technology && formValues.question.technology.technology_key,
            "code": code,
            'id': Number(rowId),
        }
        setLoading(true);
        apiCall(data, type)
    }

    useEffect(() => {
        if (rowId && activeDivs === `story_${rowId}`) {
            setLoading(true)
            dispatch(getProgramsById(rowId));
        }
        // eslint-disable-next-line
    }, [activeDivs]);

    if (loading === true && status === `${STATUSES.SUCCESS}_program_data`) {
        let data = program_Data.data;

        if (program_Data.status === 400) {
            toast.error(program_Data.message);
        }
        setFormValues(data);
        setLoading(false);
    }

    // Function to clear the output screen
    function clearOutput() {
        setUserOutput("");
    }

    const handleChangeMraks = (evt) => {
        const { name, value } = evt.target
        setFinalData({ ...finalData, [name]: value })
    }

    const submitData = (evt) => {
        evt.preventDefault();
        let maxMarks = formValues && formValues.question && formValues.question.marks

        if (finalData.obtain_mark === "" || finalData.obtain_mark < 0 || finalData.obtain_mark > maxMarks) {
            setErrStatus(true)
        }


        if (finalData.obtain_mark !== "" && finalData.obtain_mark >= 0 && finalData.obtain_mark <= maxMarks) {
            setLoading(true)
            let addvalues = {
                obtain_mark: finalData.obtain_mark,
                evaluator_remark: finalData.evaluator_remark
            }
            dispatch(postProgarmData({ ID: rowId, addvalues: addvalues }))
            setReload('submit')
        }
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_program_data_Save` && program_data_Save && program_data_Save.message) {
        dispatch(apiCallList(filterQuery))
        if (program_data_Save.status === 200) {
            toast.success(program_data_Save.message)
            hideDiv(false)
            dispatch(postProgarmData('type'))
        } else {
            toast.error(program_data_Save.message)
        }
        setLoading(false)
    }

    return (
        <div className="ss-datatable style-table" style={{ marginTop: '32px' }}>

            {/* button secton start*/}
            <div className="row">
                <div className="col ss-top-icon" style={{ textAlign: "end" }}>
                    {<Tooltip title="Close">
                        <Button
                            style={{
                                marginTop: "3px",
                                backgroundColor: "rgb(201 201 201)",
                                color: "rgb(110 110 110)"
                            }}
                            onClick={() => hideDiv(false)}
                        >
                            <Close />
                        </Button>
                    </Tooltip>}

                    {!activeClass === true ? (
                        <Tooltip title="Zoom Out">
                            <Button
                                style={{
                                    marginTop: "3px",
                                    backgroundColor: "rgb(201 201 201)",
                                    color: "rgb(110 110 110)"
                                }}
                                onClick={() => setActiveClass(!activeClass)}
                            >
                                <ZoomOutMap />
                            </Button>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Zoom In">
                            <Button
                                style={{
                                    marginTop: "3px",
                                    backgroundColor: "rgb(201 201 201)",
                                    color: "rgb(110 110 110)"
                                }}
                                onClick={() => setActiveClass(!activeClass)}
                            >
                                <ZoomInMap />
                            </Button>
                        </Tooltip>
                    )}
                </div>
            </div>

            {/* button secton end*/}
            <div className="top-headings">
                <div>
                    <button style={{ borderWidth: 0, backgroundColor: "#fff" }}>
                        <h3 className="story-epic-summary">
                            {formValues && formValues.question && formValues.question.technology && formValues.question.technology.name}
                        </h3>
                    </button>
                </div>
                {loading === true && <LoaderForm />}
            </div>

            {/* details section */}
            <div className="Edit-page" style={{ padding: "18px" }}>
                <form style={{ boxShadow: "unset", margin: "unset", padding: "unset" }}>
                    <div className="add-new-user-form">
                        <div className="formInput" style={{ flexBasis: "25%" }}>
                            <label> Marks ({formValues && formValues.question && formValues.question.marks})</label>
                            <input
                                name="obtain_mark"
                                max={formValues && formValues.question && formValues.question.marks}
                                min={0}
                                type="number"
                                onChange={(evt) => handleChangeMraks(evt)}
                            />
                            {errStatus === true && finalData.obtain_mark === "" && <p style={{ color: "#96332c", fontSize: 13 }}> Mark is requied</p>}
                            {errStatus === true && finalData.obtain_mark < 0 && <p style={{ color: "#96332c", fontSize: 13 }}> Please enter valid mark</p>}
                            {errStatus === true && finalData.obtain_mark > (formValues && formValues.question && formValues.question.marks) && <p style={{ color: "#96332c", fontSize: 13 }}> Please add max ({formValues && formValues.question && formValues.question.marks}) marks </p>}

                        </div>
                        <div className="formInput" style={{ flexBasis: "100%" }}>
                            <label>  Remark(optional)  </label>
                            <textarea
                                name="evaluator_remark"
                                rows={2}
                                onChange={(evt) => handleChangeMraks(evt)}
                            />
                        </div>
                    </div>
                </form>
                <div className="edit-details-row ss-detail-row">
                    <div className="m-2 h-14 border-2 border-black mx-auto">
                        <div className="wrapper">
                            <div className="question-wrapper">
                                <strong>Question : </strong>{" "}
                                <CopyBlock
                                    language="java"
                                    text={formValues && formValues.question && formValues.question.title ? formValues.question.title : ""}
                                    codeBlock
                                    theme={Snippet}
                                    showLineNumbers={false}
                                />
                            </div>
                            <p>
                                <strong>Answer : </strong>{" "}
                                <br />
                                {formValues && formValues.output && formValues.output.output}
                            </p>
                            <p></p>

                            <div className="main" style={{ marginBottom: "200px" }}>

                                <div className="left-container" style={{ float: "left", width: "50%", height: "calc(50vh - 100px)" }}>
                                    <Editor
                                        options={formValues && formValues.question && formValues.question.id ? { fontSize: 20, readOnly: true } : { fontSize: 20, readOnly: true }}
                                        width="100%"
                                        theme={'vs-dark'}
                                        language={formValues && formValues.question && formValues.question.technology && formValues.question.technology.technology_key}
                                        defaultLanguage="javascript"
                                        value={formValues && formValues.file_content ? formValues.file_content : ""}
                                        saveViewState={true}
                                        onChange={(value) => { setUserCode(value); }}
                                    />
                                    <button className="run-btn" onClick={() => compile()}>
                                        Re-run
                                    </button>
                                </div>
                                <div className="right-container" style={{ float: "left", width: "50%", height: "calc(50vh - 100px)" }}>
                                    <h4>Output:</h4>
                                    {loading ? (
                                        <div className="spinner-box">
                                            <CircularProgress />
                                        </div>
                                    ) : (
                                        <div className="output-box">
                                            <pre>{userOutput}</pre>
                                            <button onClick={() => { clearOutput() }}
                                                className="clear-btn">
                                                Clear
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <p> &nbsp;</p>
                <button
                    style={{ backgroundColor: "#0052cc", marginBottom: "50px", color: "white" }}
                    className="btn btn-submit"
                    onClick={submitData}>
                    Submit
                </button>
            </div>

        </div>

    )
}

export default EvaluatePage