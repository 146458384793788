import React from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./resource.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resourceList, resourceDeleteById} from "../../redux/features/resourceSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { TablePagination } from "@mui/material";
import LoaderForm from "../../components/common-function/LoaderForm";
import { toast, ToastContainer } from "react-toastify";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { resourceListOption, roleListOption } from "../../redux/features/optionsSlice";
const user = LocalStorageDataService.userDetail();
const tmsRole = user && user.tms_role
const roleWiseFilter = tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ? `{"skip":0, "limit":10,"order_by":{"column":"-id"}}` : `{"filter":{"id":{"value":${user.id},"operation": "eq"}},"skip":0,"limit":10,"order_by":{"column":"-id"}}`

const ResourcesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { data, status, error, deleteResource, syncResource } = useSelector(state => state.resource)
  const { data: list } = data;
  const { resourcesName, resourcesEmail, roles_option } = useSelector(state=>state.dropDownOption)
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  const [syncLoading, setSyncLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(roleWiseFilter)
  const [pageSize, setPageSize] = useState(10);
  const [limit, setLimit] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: true });

  useEffect(() => {
    dispatch(resourceList(filterQuery))
    dispatch(roleListOption())
    dispatch(resourceListOption())
    if (status === STATUSES.FAILURE) {
      errorHandling(error, navigate)
    }
    // eslint-disable-next-line
  }, [])


  const handleDelete = (id) => {
    setDeleteId(id)
    setOpen(true)
  }

  if (syncLoading === true && status === `${STATUSES.SUCCESS}_deleteResource` && deleteResource && deleteResource.message) {
    setSyncLoading(false)
    if (deleteResource.status === 200) {
      toast.success(deleteResource.message)
    } else {
      toast.error(syncResource.message)
    }
    setTimeout(() => {
      dispatch(resourceList(roleWiseFilter))
    }, 500);
  }

  const handleClickOpen = (id) => {
    const request = { status: 3 }
    setSyncLoading(true)
    if (open === true) {
      dispatch(resourceDeleteById(id, request))
      setOpen(false)
    }
  }

  const actionColumn = [
    {
      field: "id",
      headerName: "User Name",
      width: 200,
      type: 'singleSelect',
      valueOptions: resourcesName,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {/* {params.row.user && params.row.user.name} */}
            {params.row.first_name} {params.row.last_name}
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 300,type: 'singleSelect', valueOptions: resourcesEmail },
    { field: "contact_no", headerName: "Contact No.", width: 150 },
    {
      field: "tms_role",
      headerName: "Role",
      width: 150,
      type: 'singleSelect',
      valueOptions: roles_option,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.tms_role && params.row.tms_role.role_name && params.row.tms_role.role_name}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      type: 'singleSelect',
      valueOptions: [{value:'True', label:'Active'}, {value:'False', label:'Inactive'}],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status === true ? 'Active' : 'Inactive'}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent moduleName="resource" rowid={params.row.id} addMoreData={false} editLinkUrl={`edit/${params.row.id}`} viewLinkUrl={`view/${params.row.id}`} isDelete={true} deleteIconName="ArchiveIcon" editButton={false} viewButton={false} deleteButton={handleDelete} />
          </div>
        );
      },
    },
  ];

  const onFilterChange = (filterModel) => {
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
          filterModel.items[0].operatorValue === "is" ? 'eq' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
      const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(resourceList(filterData))
    }
    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0)
      setPageSize(10)
      setFilterQuery(roleWiseFilter)
      dispatch(resourceList(roleWiseFilter))
    }
  }

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    dispatch(resourceList(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setSyncLoading(false)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    dispatch(resourceList(JSON.stringify(newFilterData)))
  }

  const handleClose = () => {
    setOpen(false)
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(list && list.rows && list.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // sorting
  const handleSort = (sort) => {
    if (sort.length) {
      let newFilterData = JSON.parse(filterQuery);
      newFilterData['skip'] = 0;
      newFilterData['limit'] = limit;
      newFilterData['order_by'] = {
        "column": sort[0].sort === "desc" ?
          '-' + sort[0].field : sort[0].field
      }
      setFilterQuery(JSON.stringify(newFilterData))
      dispatch(resourceList(JSON.stringify(newFilterData)))
    } else {
      setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
    }
  }

  return (
    <div className="resource">
      <Sidebar />
      <div className="resourceContainer">
        <Navbar />
        <ToastContainer />
        <div className="datatable">
          <div className="top-headings">
            <h3>Users  List</h3>
            <div>
              <Tooltip title="Add New User">
                <Link to="add" className="btn-add-new">
                  <AddIcon />
                </Link>
              </Tooltip>
            </div>
          </div>

          {(syncLoading === true || status === STATUSES.LOADING) && <LoaderForm />}
          <DataGrid
             columnVisibilityModel={columnVisibilityModel}
             onColumnVisibilityModelChange={(newModel) =>
                 setColumnVisibilityModel(newModel)
             }
            className="datagrid"
            rows={list && list.rows ? list.rows : []}
            columns={actionColumn}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            components={{
              Pagination: CustomPagination,
            }}
            onSortModelChange={(sort) => handleSort(sort)}
          />
          {error && <p style={{ color: "#96332c", fontSize: 12 }}> {error.message}</p>}
        </div>


        {/* for delete popup */}
        <DeleteDialogModal
          open={open}
          onClose={handleClose}
          heading={'Delete ?'}
          paragraph={'Are you sure to remove user'}
          handleArchive={handleClickOpen}
          id={deleteId}
        />
      </div>

    </div>
  )
}

export default ResourcesList