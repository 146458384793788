import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { ScheduleColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, ArrowBack, Close } from "@mui/icons-material";
import { InputFields, SingleDatePickerInputField } from "../../components/common-function/InputFields";
import { getQuestionSetList, scheduleCreate, batch, scheduleGetById, scheduleList, scheduleUpdate } from "../../redux/features/masterSlice";
import Select from 'react-select';
import moment from "moment";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
// import { setDefaultLocale } from "react-datepicker";

const initialFormData = Object.freeze({
    display_name: "",
    set: "",
    schedule_name: ""
});

const formErrData = Object.freeze({
    schedule_nameErr: "Title type is required",
    setErr: "Set is required"
});


const ScheduleForm = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const {
        status,
        error,
        questionset_List,
        batch_list,
        schedule_List,
        schedule_Create,
        schedule_By_Id,
        schedule_Update
    } = useSelector(state => state.master)

    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [startDate, setStartDate] = useState(null)
    const [expiryDate, setExpiryDate] = useState(null)
    const [batchname, setBatchName] = useState("")

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        dispatch(scheduleList(filterQuery))
        dispatch(batch(`{ "filter": { "status": { "value": "True", "operation": "eq" } },"order_by": { "column": "-id" }, "no_limit": True }`))
        // eslint-disable-next-line
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0) }
        setOpen(false);
        setFormValues(initialFormData);;
        setErrStatus(false)
        setExpiryDate(null)
        setStartDate(null)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_schedule_Update` && schedule_Update && schedule_Update.message) {
        if (schedule_Update.status === 200) {
            toast.success(schedule_Update.message)
            dispatch(scheduleList(filterQuery))
            handleClose()
        } else {
            toast.error(schedule_Update.message)
        }
        setReload(false)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_schedule_Create` && schedule_Create && schedule_Create.message) {
        if (schedule_Create.status === 200) {
            toast.success(schedule_Create.message)
            dispatch(scheduleList(filterQuery))
            handleClose()
        } else {
            toast.error(schedule_Create.message)
        }
        setReload(false)
    }


    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
        setBatchName(evt.label)
        if (batch_list.data?.rows) {
            let arr = batch_list.data?.rows
            let obj = arr.find(o => o.batch === batchname);
            if (obj?.start_date) {
                setStartDate(obj?.start_date)
            }
        }

    }

    const changeStartDate = (evt) => {
        if (expiryDate) {
            let date_1 = new Date(expiryDate)
            let date_2 = new Date(evt)
            if (date_1 < date_2) {
                setStartDate(evt)
                setExpiryDate(null)
                toast.warning('Start Date  is greater than Expiry Date"')
            } else {
                setStartDate(evt)
            }
        } else {
            setStartDate(evt)
        }
    }

    const changeExpireDate = (evt) => {
        if (startDate) {
            let date_1 = new Date(startDate)
            let date_2 = new Date(evt)
            if (date_1 > date_2) {
                setExpiryDate(evt)
                setStartDate(null)
                toast.warning('Start Date  is greater than Expiry Date"')
            } else {
                setExpiryDate(evt)
            }
        } else {
            setExpiryDate(evt)
        }
    }

    const handleBatchChange = (e) => {
        dispatch(getQuestionSetList(`{ "filter": { "set__status": { "value": "True", "operation": "eq" } }, "order_by": { "column": "-id" },"no_limit": True }`))

        setFormValues({ ...formValues, batch: e.value })
        if (batch_list.data?.rows) {
            let arr = batch_list.data?.rows
            let obj = arr.find(o => o.id === e.value);

            if (obj?.start_date) {
                let a_new_date = new Date(obj.start_date);
                if (a_new_date > new Date()) {
                    let startDateValue = moment(a_new_date).format('YYYY-MM-DD');
                    setStartDate(startDateValue)
                } else {
                    setStartDate(moment().format('YYYY-MM-DD'));
                }

            }
            else {
                setStartDate(moment().format('YYYY-MM-DD'));
            }

            // End date (Expiery date)

            if (obj?.end_date) {
                let a_new_date = new Date(obj.end_date);
                if (a_new_date > new Date()) {
                    let endDateValue = moment(a_new_date).format('YYYY-MM-DD');
                    setExpiryDate(endDateValue);
                } else {
                    setExpiryDate(moment().format('YYYY-MM-DD'));
                }

            }
            else {
                setExpiryDate(moment().format('YYYY-MM-DD'));
            }
        }
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.schedule_name && formValues.set && formValues.batch) {
            let addvalues = {
                schedule_name: formValues.schedule_name,
                batch: formValues.batch,
                set_id: formValues.set && formValues.set.value ? formValues.set.value : formValues.set,
                start_date: startDate ? startDate : expiryDate ? moment().format('YYYY-MM-DD') : "",
                end_date: expiryDate ? expiryDate : "",
            }
            setErrStatus(false)
            setReload("submit")
            addvalues.end_date === "" && delete addvalues['end_date']
            addvalues.start_date === "" && delete addvalues['start_date']
            if (ID) {
                addvalues['updated_by'] = GetLocalstorage.userDetail().id
                //addvalues['status'] = !formValues.status
                dispatch(scheduleUpdate({ ID, addvalues }))
            } else {
                addvalues['created_by'] = GetLocalstorage.userDetail().id
                dispatch(scheduleCreate(addvalues))
            }
        } else {
            setErrStatus(true)
        }
    }

    const prepaireSetDropDown = (technologies) => {
        let show_selected = '';
        let technologyArray = []
        let levelArray = []
        technologies.length > 0 && technologies.map(e => {
            e.name && technologyArray.push(e.name)
            e.level && levelArray.push(e.level.level_name)
            return show_selected = { "technology": technologyArray, "level": levelArray }

        })
        return show_selected
    }
    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_schedule_By_Id` && schedule_By_Id && schedule_By_Id.data) {
        let editData = schedule_By_Id.data
        let label = editData.set_id && editData.set_id.set_name
        if (editData.set_id && editData.set_id.length > 0) {
            editData.set_id && editData.set_id.map(option => {
                if (option.technology && option.technology.length > 0) {
                    label = editData.set_id && editData.set_id[0].set_name + "/" + prepaireSetDropDown(option.technology).technology.map((e, index) => `${index ? ' ' : ''}${(e).trim()}`) + "/" + prepaireSetDropDown(option.technology).level.map((e, index) => `${index ? ' ' : ''}${(e).trim()}`)
                }
                return label
            })
        }
        // if(editData.set_id && editData.set_id.technology){
        //     label = editData.set_id && editData.set_id.set_name + "/" + editData.set_id.technology.name;
        //     if(editData.set_id.level){
        //         label = editData.set_id.set_name + "/" + editData.set_id.technology.name + "/" + editData.set_id.level.level_name
        //     }
        // }

        
        setFormValues({
            schedule_name: editData.schedule_name,
            display_name: { label: editData.batch && editData.batch.display_name, value: editData.batch_id },
            set: { label: label, value: editData.set_id_id },
            status: editData.status,
            batch: editData.batch_id
        })
        editData.start_date && setStartDate((editData.start_date).split("T")[0])
        editData.end_date && setExpiryDate((editData.end_date).split("T")[0])


        setID(editData.id)
        setOpen(true)
        setReload(false)
        dispatch(getQuestionSetList(`{ "filter": { "set__status": { "value": "True", "operation": "eq" } }, "order_by": { "column": "-id" },"no_limit": True }`))

    }

    const handleClickEdit = (id) => {
        dispatch(scheduleGetById(id))
        setReload('edit_data')
    }

    const handleArchive = (data) => {
        let addvalues = {
            schedule_name: data.schedule_name,
            set_id: data.set && data.set_id_id,
            start_date: data.start_date ? (data.start_date).split("T")[0] : "",
            end_date: data.end_date ? (data.end_date).split("T")[0] : "",
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }

        let ID = data.id;
        addvalues.end_date === "" && delete addvalues['end_date']
        addvalues.start_date === "" && delete addvalues['start_date']
        setReload("submit")
        dispatch(scheduleUpdate({ ID, addvalues }))

    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(scheduleList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(scheduleList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(schedule_List && schedule_List.data && schedule_List.data.rows && schedule_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(scheduleList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="master-schedule"
                            rowid={params.row.id}
                            addMoreData={params.row}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={handleArchive} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'date' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(scheduleList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(scheduleList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }


    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Schedule List</h3>
                        <div>
                            <Tooltip title="Add New Schedule">
                                <button onClick={handleOpen} className="btn-add-new"><Add /> </button>
                            </Tooltip>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={schedule_List && schedule_List.data && schedule_List.data.rows ? schedule_List.data.rows : []}
                        columns={ScheduleColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add New'} Schedule</DialogTitle>

                    <DialogContent style={{ height: "400px" }}>
                        <form>
                            <div className="add-new-master-form">
                                <InputFields
                                    label={"Schedule Title"}
                                    name="schedule_name"
                                    defaultValue={formValues.schedule_name}
                                    type="text"
                                    placeholder="Schedule title"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.schedule_nameErr}
                                />
                                <div className="formInput">
                                    <label >
                                        Batch
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select'}
                                        defaultValue={formValues.display_name}
                                        isSearchable={true}
                                        name={'display_name'}
                                        onChange={e => {
                                            handleBatchChange(e)
                                        }}
                                        options={
                                            batch_list && batch_list.data && batch_list.data.rows.map((option) => {
                                                return {
                                                    label: option.display_name,
                                                    value: option.id,
                                                    name: 'display_name'
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues.batch === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.setErr}</p>}

                                </div>
                                {formValues.batch &&
                                    <div className="formInput">
                                        <label >
                                            Set
                                        </label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder={'Select'}
                                            defaultValue={formValues.set}
                                            isSearchable={true}
                                            name={'set'}
                                            onChange={handleChange}
                                            options={
                                                questionset_List && questionset_List.data && questionset_List.data.rows.map((option) => {
                                                    return { label: option.technology && option.technology.length > 0 ? option.set_name + "/" + prepaireSetDropDown(option.technology).technology.map((e, index) => `${index ? ' ' : ''}${(e).trim()}`) + "/" + prepaireSetDropDown(option.technology).level.map((e, index) => `${index ? ' ' : ''}${(e).trim()}`) : option.set_name, value: option.id, name: 'set' }
                                                })
                                            }
                                        />
                                        {errStatus === true && formValues.set === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.setErr}</p>}

                                    </div>
                                }

                                {formValues.batch &&
                                    <SingleDatePickerInputField
                                        label={'Start Date'}
                                        placeholder="Select"
                                        defaultValue={startDate}
                                        value={startDate}
                                        name={"scheduled_dated"}
                                        onChange={changeStartDate}
                                        format={'yyyy-MM-dd'}
                                        minDate={moment().format('YYYY-MM-DD')}
                                    // errStatus={errStatus}
                                    // formErr={formErr.dateErr}
                                    />
                                }

                                {formValues.batch &&
                                    <SingleDatePickerInputField
                                        label={'Expiry Date'}
                                        placeholder="Select"
                                        defaultValue={expiryDate}
                                        value={expiryDate}
                                        name={"scheduled_dated"}
                                        onChange={changeExpireDate}
                                        format={'yyyy-MM-dd'}
                                        minDate={startDate ? moment(startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}

                                    // errStatus={errStatus}
                                    // formErr={formErr.dateErr}
                                    />}

                            </div>
                            <button
                                className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                onClick={submitData}
                                disabled={reload === "submit" ? true : false}
                            >
                                {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>

            </div>
        </div >
    )
}

export default ScheduleForm