import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getCandidateExamList = createAsyncThunk("get/candiadte-exam-list", async (data) => {
    const res = await dataService.all_candidate_exam_list_by_EXT(data);
    const resp = res.data;
    return resp;
});

const candidateExamListSlice = createSlice({
    name: "candidateExamListSlice",
    initialState: {
        status: STATUSES.IDLE,
        error: '',
        candidateExamList: [],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get 
            .addCase(getCandidateExamList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCandidateExamList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.candidateExamList = action.payload;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        ...e,
                        id: e.id,
                        exam_schedule_id: e.exam_schedule_id,
                        exam_schedule_name: e.exam_schedule && e.exam_schedule.schedule_name,
                        set_id: e.exam_schedule && e.exam_schedule.set_id && e.exam_schedule.set_id.length > 0 && e.exam_schedule.set_id[0],
                        technology_name: e.exam_schedule && e.exam_schedule.set_id && e.exam_schedule.set_id.length > 0 && e.exam_schedule.set_id[0].technology,
                        exam_schedule__set_id: e.exam_schedule && e.exam_schedule.set_id_id,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.candidateExamList = newArray2 && newArray2.data ? newArray2 : action.payload;
                state.error = action.error
            })
            .addCase(getCandidateExamList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
    },
});


export default candidateExamListSlice.reducer;