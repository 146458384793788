import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

// get all menu list
export const getMenuList = createAsyncThunk(
    "get/menu-list",
    async (data) => {
        const res = await dataService.menuList(data);
        const resp = res.data;
        return resp;
    });
export const postBaseMenu = createAsyncThunk(
    "post/base-menu",
    async (data) => {
        const res = await dataService.postmenu(data);
        const resp = res.data;
        return resp;
    });

export const getRoleMenuList = createAsyncThunk(
    "get/role-menu-list",
    async (data) => {
        const res = await dataService.getRoleMenu(data);
        const resp = res.data;
        return resp;
    });
// base/menu-role
export const postSingleBaseMenu = createAsyncThunk(
    "post-single/base-menu",
    async (data) => {
        const res = await dataService.postSingleBaseMenu(data);
        const resp = res.data;
        return resp;
    });
export const postAssignMenuRole = createAsyncThunk(
    "post-multipe/base-menu",
    async (data) => {
        const res = await dataService.postAssignMenuRole(data);
        const resp = res.data;
        return resp;
    });
//get all menu for parent
export const getAllMenuList = createAsyncThunk("get/all-menu-list", async (data) => {
    const res = await dataService.menuList(data);
    const resp = res.data;
    return resp;
});

export const getAsigneRoleMenuList = createAsyncThunk("get/assigne-role-menu-list", async (data) => {
    const res = await dataService.getRoleMenu(data);
    const resp = res.data;
    return resp;
});

export const postUploadResume = createAsyncThunk("post/upload-resume", async (data) => {
    const res = await dataService.postResume(data);
    const resp = res.data;
    return resp;
});

//////questiontype apis///////
export const getAllQuestionTypeList = createAsyncThunk("questiontype/all-list", async (data) => {
    const res = await dataService.all_questiontype_list(data);
    return res.data;
});

export const getQuestionTypeList = createAsyncThunk("questiontype/list", async (data) => {
    const res = await dataService.questionType_list(data);
    return res.data;
});

export const questiontypeCreate = createAsyncThunk("questiontype/create", async (data) => {
    const res = await dataService.questionType_create(data);
    return res.data;
});

export const questiontypeEdit = createAsyncThunk("questiontype/edit", async (id) => {
    const res = await dataService.questionType_edit(id);
    return res.data;
});

export const questiontypeUpdate = createAsyncThunk("questiontype/update", async (data) => {
    const res = await dataService.questionType_update(data.ID, data.addvalues);
    return res.data;
});

export const questiontypeArchive = createAsyncThunk("questiontype/archive", async (id) => {
    const res = await dataService.questionType_archive(id);
    return res.data;
});

//////Technology apis///////
export const getAllTechnologyList = createAsyncThunk("technology/all-list", async (data) => {
    const res = await dataService.all_technology_list(data);
    return res.data;
});

export const getTechnologyList = createAsyncThunk("technology/list", async (data) => {
    const res = await dataService.technology_list(data);
    return res.data;
});

export const technologyCreate = createAsyncThunk("technology/create", async (data) => {
    const res = await dataService.technology_create(data);
    return res.data;
});

export const technologyEdit = createAsyncThunk("technology/edit", async (id) => {
    const res = await dataService.technology_edit(id);
    return res.data;
});

export const technologyUpdate = createAsyncThunk("technology/update", async (data) => {
    const res = await dataService.technology_update(data.ID, data.addvalues);
    return res.data;
});

export const technologyArchive = createAsyncThunk("technology/archive", async (id) => {
    const res = await dataService.technology_archive(id);
    return res.data;
});
//////QuestionLevel apis///////
export const getAllQuestionLevelList = createAsyncThunk("questionlevel/all-list", async (data) => {
    const res = await dataService.all_questionlevel_list(data);
    return res.data;
});

export const getQuestionLevelList = createAsyncThunk("questionlevel/list", async (data) => {
    const res = await dataService.questionlevel_list(data);
    return res.data;
});

export const questionlevelCreate = createAsyncThunk("questionlevel/create", async (data) => {
    const res = await dataService.questionlevel_create(data);
    return res.data;
});

export const questionlevelEdit = createAsyncThunk("questionlevel/edit", async (id) => {
    const res = await dataService.questionlevel_edit(id);
    return res.data;
});

export const questionlevelUpdate = createAsyncThunk("questionlevel/update", async (data) => {
    const res = await dataService.questionlevel_update(data.ID, data.addvalues);
    return res.data;
});

export const questionlevelArchive = createAsyncThunk("questionlevel/archive", async (id) => {
    const res = await dataService.questionlevel_archive(id);
    return res.data;
});

//////Batch list apis///////

export const batch = createAsyncThunk("batch/batch-list", async (data) => {
    if (data === "type") {
        return [];
    }
    const res = await dataService.batch_list(data);
    return res.data;
});

export const batchTechnologyOnFilter = createAsyncThunk("batch/batch-tech", async (data) => {
    const res = await dataService.batch_list_technology_filter(data);
    return res.data;
});

export const batchCreate = createAsyncThunk("batch/batch-create", async (data) => {
    const res = await dataService.batch_create(data);
    return res.data;
});

export const batchEdit = createAsyncThunk("batch/batch-edit", async (id) => {
    const res = await dataService.batch_edit(id);
    return res.data;
})

export const batchUpdate = createAsyncThunk("batch/batch-update", async ({ id, data }) => {
    const res = await dataService.batch_update(id, data);
    return res.data;
});

export const batchById = createAsyncThunk("batch/batch-by-id", async (id) => {
    const res = await dataService.batch_by_id(id);
    return res.data;
});

export const batchByTech = createAsyncThunk("batch/batch-by-tech", async (data) => {
    const res = await dataService.batch_by_tech(data);
    return res.data;
});


//////questionSet apis///////
export const getQuestionSetList = createAsyncThunk("questionset/all-list", async (data) => {
    const res = await dataService.questionSet_list(data);
    return res.data;
});

export const questionSetCreate = createAsyncThunk("questionset/create", async (data) => {
    const res = await dataService.questionSet_create(data);
    return res.data;
});

export const questionSetEdit = createAsyncThunk("questionset/edit", async (id) => {
    const res = await dataService.questionSet_edit(id);
    return res.data;
});

export const questionSetUpdate = createAsyncThunk("questionset/update", async (data) => {
    const res = await dataService.questionSet_update(data.ID, data.addvalues);
    return res.data;
});

export const questionSetArchive = createAsyncThunk("questionSet/archive", async (id) => {
    const res = await dataService.questionSet_archive(id);
    return res.data;
});

//////question apis///////
export const getQuestionList = createAsyncThunk("question/all-list", async (data) => {
    const res = await dataService.question_list(data);
    return res.data;
});

export const questionCreate = createAsyncThunk("question/create", async (data) => {
    console.log(data , "data")
    const res = await dataService.question_create(data);
    return res.data;
});

export const questionEdit = createAsyncThunk("question/edit", async (id) => {
    const res = await dataService.question_edit(id);
    return res.data;
});

export const questionUpdate = createAsyncThunk("question/update", async (data) => {
    const res = await dataService.question_update(data.ID, data.addvalues);
    return res.data;
});

export const questionArchive = createAsyncThunk("question/archive", async (id) => {
    const res = await dataService.question_archive(id);
    return res.data;
});

export const downloadQuestionSample = createAsyncThunk("download-question-sample", async () => {
    const res = await dataService.download_samplefile_question();
    return res.data;
});

export const importQusetionFile = createAsyncThunk("import-question", async (id) => {
    const res = await dataService.import_file_question(id);
    return res.data;
});

export const postAnswerOptions = createAsyncThunk("answer-options/create", async (data) => {
    const res = await dataService.answer_option_create(data);
    return res.data;
});

export const updateAnswerOptions = createAsyncThunk("answer-options/update", async (data) => {
    const res = await dataService.answer_option_update(data.ID, data.addvalues);
    return res.data;
});

export const getAnswerOptions = createAsyncThunk("answer-options", async (filter) => {
    const res = await dataService.answer_option_list(filter);
    return res.data;
});

export const deleteAnswerOptions = createAsyncThunk("answer-options/archive", async (id) => {
    const res = await dataService.answer_option_delete(id);
    return res.data;
});
export const getQualificationList = createAsyncThunk("qualification/list", async (data) => {
    const res = await dataService.qualification_list(data);
    return res.data;
});

export const qualificationCreate = createAsyncThunk("qualification/create", async (data) => {
    const res = await dataService.qualification_create(data);
    return res.data;
});

export const qualificationEdit = createAsyncThunk("qualification/edit", async (id) => {
    const res = await dataService.qualification_edit(id);
    return res.data;
});

export const qualificationUpdate = createAsyncThunk("qualification/update", async (data) => {
    const res = await dataService.qualification_update(data.ID, data.addvalues);
    return res.data;
});

export const qualificationArchive = createAsyncThunk("qualification/archive", async (id) => {
    const res = await dataService.qualification_archive(id);
    return res.data;
});

export const scheduleCreate = createAsyncThunk("schedule/create", async (data) => {
    const res = await dataService.schedule_create(data);
    return res.data;
});

export const scheduleUpdate = createAsyncThunk("schedule/update", async (data) => {
    const res = await dataService.schedule_update(data.ID, data.addvalues);
    return res.data;
});
export const scheduleGetById = createAsyncThunk("schedule/get-by-id", async (data) => {
    const res = await dataService.schedule_get_by_id(data);
    return res.data;
});

export const scheduleList = createAsyncThunk("schedule/list", async (filter) => {
    const res = await dataService.schedule_list(filter);
    return res.data;
});

//////Industry apis///////
export const getAllIndustryList = createAsyncThunk("industry/all-list", async (data) => {
    const res = await dataService.all_industry_list(data);
    return res.data;
});

export const getIndustryList = createAsyncThunk("industry/list", async (data) => {
    const res = await dataService.industry_list(data);
    return res.data;
});

export const industryCreate = createAsyncThunk("industry/create", async (data) => {
    const res = await dataService.industry_create(data);
    return res.data;
});

export const industryEdit = createAsyncThunk("industry/edit", async (id) => {
    const res = await dataService.industry_edit(id);
    return res.data;
});

export const industryUpdate = createAsyncThunk("industry/update", async (data) => {
    const res = await dataService.industry_update(data.ID, data.addvalues);
    return res.data;
});

export const industryArchive = createAsyncThunk("industry/archive", async (id) => {
    const res = await dataService.industry_archive(id);
    return res.data;
});

//////Related Skill apis///////

export const getAllRelatedSkillList = createAsyncThunk("related-skills/all-list", async (data) => {
    const res = await dataService.all_related_skill_list(data);
    return res.data;
});

export const getRelatedSkillList = createAsyncThunk("related-skills/list", async (data) => {
    const res = await dataService.related_skill_list(data);
    return res.data;
});

export const relatedSkillCreate = createAsyncThunk("related-skills/create", async (data) => {
    const res = await dataService.related_skill_create(data);
    return res.data;
});

export const relatedSkillEdit = createAsyncThunk("related-skills/edit", async (id) => {
    const res = await dataService.related_skill_edit(id);
    return res.data;
});

export const relatedSkillUpdate = createAsyncThunk("related-skills/update", async (data) => {
    const res = await dataService.related_skill_update(data.ID, data.addvalues);
    return res.data;
});

export const relatedSkillArchive = createAsyncThunk("related-skills/archive", async (id) => {
    const res = await dataService.related_skill_archive(id);
    return res.data;
});

//////Industry - Department apis///////

export const getAllIndustryDepartmentList = createAsyncThunk("industry-department/all-list", async (data) => {
    const res = await dataService.all_industry_department_list(data);
    return res.data;
});

export const getIndustryDepartmentList = createAsyncThunk("industry-department/list", async (data) => {
    const res = await dataService.industry_department_list(data);
    return res.data;
});

export const IndustryDepartmentCreate = createAsyncThunk("industry-department/create", async (data) => {
    const res = await dataService.industry_department_create(data);
    return res.data;
});

export const IndustryDepartmentEdit = createAsyncThunk("industry-department/edit", async (id) => {
    const res = await dataService.industry_department_edit(id);
    return res.data;
});

export const IndustryDepartmentUpdate = createAsyncThunk("industry-department/update", async (data) => {
    const res = await dataService.industry_department_update(data.ID, data.addvalues);
    return res.data;
});

export const IndustryDepartmentArchive = createAsyncThunk("industry-department/archive", async (id) => {
    const res = await dataService.industry_department_archive(id);
    return res.data;
});
export const getAllIndustryDepSpecList = createAsyncThunk("industry-department-specialisation/all-list", async (data) => {
    const res = await dataService.all_industryDepartmentSpecialisation_list(data);
    return res.data;
});

export const getIndustryDepSpecList = createAsyncThunk("industry-department-specialisation/list", async (data) => {
    const res = await dataService.industryDepartmentSpecialisation_list(data);
    return res.data;
});

export const IndustryDepSpecCreate = createAsyncThunk("industry-department-specialisation/create", async (data) => {
    const res = await dataService.industryDepartmentSpecialisation_create(data);
    return res.data;
});

export const IndustryDepSpecEdit = createAsyncThunk("industry-department-specialisation/edit", async (id) => {
    const res = await dataService.industryDepartmentSpecialisation_edit(id);
    return res.data;
});

export const IndustryDepSpecUpdate = createAsyncThunk("industry-department-specialisation/update", async (data) => {
    const res = await dataService.industryDepartmentSpecialisation_update(data.ID, data.addvalues);
    return res.data;
});

export const IndustryDepSpecArchive = createAsyncThunk("industry-department-specialisation/archive", async (id) => {
    const res = await dataService.industryDepartmentSpecialisation_archive(id);
    return res.data;
});
// industry dep specialisations and skill mapping
export const getIndsDepSpecSkill = createAsyncThunk("indsDepSpecSkill/list", async (data) => {
    const res = await dataService.indus_dep_spec_skill_list(data);
    return res.data;
});

export const indsDepSpecSkillCreat = createAsyncThunk("indsDepSpecSkill/create", async (data) => {
    const res = await dataService.indus_dep_spec_skill_create(data);
    return res.data;
});

export const indsDepSpecSkillEdit = createAsyncThunk("indsDepSpecSkill/edit", async (id) => {
    const res = await dataService.indus_dep_spec_skill_edit(id);
    return res.data;
});

export const indsDepSpecSkillUpdate = createAsyncThunk("indsDepSpecSkill/update", async (data) => {
    const res = await dataService.indus_dep_spec_skill_update(data.ID, data.addvalues);
    return res.data;
});

export const getIndusDepSpecListData = createAsyncThunk("industry-department-specialization/list", async (data) => {
    const res = await dataService.industry_depspecialization_list_data(data);
    return res.data;
});

// industry department tech and skill maping

export const getIndsDepTechSkill = createAsyncThunk("industry-department-tech-skill/list", async (data) => {
    const res = await dataService.indus_dep_tech_skill_list(data);
    return res.data;
});

export const IndsDepTechSkillCreate = createAsyncThunk("industry-department-tech-skill/create", async (data) => {
    const res = await dataService.indus_dep_tech_skill_create(data);
    return res.data;
});

export const IndsDepTechSkillEdit = createAsyncThunk("industry-department-tech-skill/edit", async (id) => {
    const res = await dataService.indus_dep_tech_skill_edit(id);
    return res.data;
});

export const IndsDepTechSkillUpdate = createAsyncThunk("industry-department-tech-skill/update", async (data) => {
    const res = await dataService.indus_dep_tech_skill_update(data.ID, data.addvalues);
    return res.data;
});

// export const IndsDepTechSkillArchive = createAsyncThunk("industry-department-tech-skill/archive", async (id) => {
//     const res = await dataService.industry_department_archive(id);
//     return res.data;
// });

// export const getIndusDepSpecListData = createAsyncThunk("industry-department-specialization/list", async (data) => {
//     const res = await dataService.industry_depspecialization_list_data(data);
//     return res.data;
// });

// type of test

export const getTestTypeList = createAsyncThunk("test-type/list", async (data) => {
    const res = await dataService.test_type_list(data);
    return res.data;
});

export const testTypeCreate = createAsyncThunk("test-type/create", async (data) => {
    const res = await dataService.test_type_create(data);
    return res.data;
});



const masterSlice = createSlice({
    name: "master",
    initialState: {
        status: 'idle',
        error: '',

        menuList: [],
        createMenu: [],
        createRoleMenu: [],
        roleMenuList: [],
        createAssignRoleMenu: [],
        allMenuList: [],
        assigneMenu: [],

        questiontype_AllList: [],
        questiontype_Create: [],
        questiontype_List: [],
        questiontype_Edit: [],
        questiontype_Update: [],
        questiontype_Archive: [],

        technology_AllList: [],
        technology_Create: [],
        technology_List: [],
        technology_Edit: [],
        technology_Update: [],
        technology_Archive: [],

        questionlevel_AllList: [],
        questionlevel_Create: [],
        questionlevel_List: [],
        questionlevel_Edit: [],
        questionlevel_Update: [],
        questionlevel_Archive: [],

        questionset_List: [],
        questionset_Create: [],
        questionset_Edit: [],
        questionset_Update: [],
        questionset_Archive: [],

        question_List: [],
        question_Create: [],
        question_Edit: [],
        question_Update: [],
        question_Archive: [],

        download_question_sample: [],
        import_question_file: [],

        answer_options_Create: [],
        answer_options_List: [],
        answer_options_Update: [],
        answer_options_Delete: [],

        batch_list: [],
        batch_created: [],
        batch_updated: [],
        batch_from_id: [],
        technology_filter: [],

        qualification_Create: [],
        qualification_List: [],
        qualification_Edit: [],
        qualification_update: [],
        qualification_Archive: [],

        schedule_Create: [],
        schedule_List: [],
        schedule_Update: [],
        schedule_By_Id: [],
        schedules_option: [],


        industry_AllList: [],
        industryOptions: [],
        industry_Create: [],
        industry_List: [],
        industry_Edit: [],
        industry_update: [],
        industry_Archive: [],

        related_skill_AllList: [],
        related_skill_Create: [],
        related_skill_List: [],
        related_skill_Edit: [],
        related_skill_Update: [],
        related_skill_Archive: [],

        industry_department_AllList: [],
        industry_department_Create: [],
        industry_department_List: [],
        industry_department_Edit: [],
        industry_department_Update: [],
        industry_department_Archive: [],
        department_List: [],

        industryDepartmentSpecialisation_AllList: [],
        industryDepartmentSpecialisation_Create: [],
        industryDepartmentSpecialisation_List: [],
        industryDepartmentSpecialisation_Edit: [],
        industryDepartmentSpecialisation_Update: [],
        industryDepartmentSpecialisation_Archive: [],



        indsDepTechSkill_List: [],
        indsDepTechSkill_Create: [],
        indsDepTechSkill_Edit: [],
        indsDepTechSkill_Update: [],
        indsDepTechSkill_Archive: [],
        industryDepSpec_List_data: [],

        test_type_list: [],
        test_type_create: [],

    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get menu list 
            .addCase(getMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMenuList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.menuList = action.payload;

            })
            .addCase(getMenuList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
            //post menu  
            .addCase(postBaseMenu.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postBaseMenu.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createMenu`;
                state.createMenu = action.payload;

            })
            .addCase(postBaseMenu.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createMenu`;
                state.error = action.error
            })
            //post and update single menu role
            .addCase(postSingleBaseMenu.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postSingleBaseMenu.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createRoleMenu`;
                state.createRoleMenu = action.payload;

            })
            .addCase(postSingleBaseMenu.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createRoleMenu`;
                state.error = action.error
            })
            //get menu role list
            .addCase(getRoleMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getRoleMenuList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleMenuList`;
                state.roleMenuList = action.payload;

            })
            .addCase(getRoleMenuList.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_roleMenuList`;
                state.error = action.error
            })

            //post Assign menu role 
            .addCase(postAssignMenuRole.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postAssignMenuRole.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createAssignRoleMenu`;
                state.createAssignRoleMenu = action.payload;
            })
            .addCase(postAssignMenuRole.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createAssignRoleMenu`;
                state.error = action.error
            })

            // get parent menu
            .addCase(getAllMenuList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.allMenuList = action.payload;

            })

            //get assigne menu list by role
            .addCase(getAsigneRoleMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAsigneRoleMenuList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleMenuList`;
                state.dataLoader = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        id: e.id,
                        created_by: e.created_by,
                        created_on: e.created_on,
                        menu_id: e.menu_id,
                        menu: e.menu && e.menu.url_slug,
                        menu_title: e.menu && e.menu.title,
                        menu_status: e.menu && e.menu.status,
                        parent_menu_id: e.parent_menu_id,
                        parent_menu: e.parent_menu && e.parent_menu.url_slug,
                        parent_menu_status: e.parent_menu && e.parent_menu.status,
                        role_id: e.role_id,
                        updated_by: e.updated_by,
                        updated_on: e.updated_on,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray.filter(e => e.menu_status === true && (e.parent_menu === null || e.parent_menu_status === true)), "count": count } }
                }
                state.assigneMenu = newArray2 && newArray2.data ? newArray2 : action.payload;
                localStorage.setItem('menuData', JSON.stringify(newArray2))
            })
            .addCase(getAsigneRoleMenuList.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_roleMenuList`;
                state.error = action.error
            })

            //////////questiontype store section/////////
            ///all questiontype  list
            .addCase(getAllQuestionTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllQuestionTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questiontype_AllList = action.payload;
            })
            .addCase(getAllQuestionTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd questiontype list
            .addCase(getQuestionTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questiontype_List = action.payload;
            })
            .addCase(getQuestionTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///questiontype create
            .addCase(questiontypeCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questiontypeCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questiontype_Create`;
                state.questiontype_Create = action.payload;
            })
            .addCase(questiontypeCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questiontype edit
            .addCase(questiontypeEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questiontypeEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questiontype_Edit = action.payload;
            })
            .addCase(questiontypeEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questiontype Update
            .addCase(questiontypeUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questiontypeUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questiontype_Update`;
                state.questiontype_Update = action.payload;
            })
            .addCase(questiontypeUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questiontype archive
            .addCase(questiontypeArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questiontypeArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questiontype_Archive`;
                state.questiontype_Archive = action.payload;
            })
            .addCase(questiontypeArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //////////Technology store section/////////
            ///all technology  list
            .addCase(getAllTechnologyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllTechnologyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.technology_AllList = action.payload;
            })
            .addCase(getAllTechnologyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd technology list
            .addCase(getTechnologyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTechnologyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.technology_List = action.payload;
            })
            .addCase(getTechnologyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///technology create
            .addCase(technologyCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(technologyCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_technology_Create`;
                state.technology_Create = action.payload;
            })
            .addCase(technologyCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///technology edit
            .addCase(technologyEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(technologyEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.technology_Edit = action.payload;
            })
            .addCase(technologyEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///technology Update
            .addCase(technologyUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(technologyUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_technology_Update`;
                state.technology_Update = action.payload;
            })
            .addCase(technologyUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///technology archive
            .addCase(technologyArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(technologyArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_technology_Archive`;
                state.technology_Archive = action.payload;
            })
            .addCase(technologyArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //////////QuestionLevel store section/////////
            ///all questionlevel  list
            .addCase(getAllQuestionLevelList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllQuestionLevelList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionlevel_AllList = action.payload;
            })
            .addCase(getAllQuestionLevelList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd questionlevel list
            .addCase(getQuestionLevelList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionLevelList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionlevel_List = action.payload;
            })
            .addCase(getQuestionLevelList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///questionlevel create
            .addCase(questionlevelCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionlevelCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionlevel_Create`;
                state.questionlevel_Create = action.payload;
            })
            .addCase(questionlevelCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questionlevel edit
            .addCase(questionlevelEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionlevelEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionlevel_Edit = action.payload;
            })
            .addCase(questionlevelEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questionlevel Update
            .addCase(questionlevelUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionlevelUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionlevel_Update`;
                state.questionlevel_Update = action.payload;
            })
            .addCase(questionlevelUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questionlevel archive
            .addCase(questionlevelArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionlevelArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionlevel_Archive`;
                state.questionlevel_Archive = action.payload;
            })
            .addCase(questionlevelArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questionSet store section///
            //all questionSet list
            .addCase(getQuestionSetList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionSetList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.questionset_List = action.payload;
                state.status = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        ...e,
                        status_id: e.status === true ? 'True' : 'False',
                        set__id: e.id,
                        technology__id: e.technology && e.technology.length > 0 ? e.technology[0].id : e.technology,
                        level__id: e.technology && e.technology.length > 0 ? e.technology[0].level && e.technology[0].level.id : e.technology
                        // id: e.id,
                        // set_name: e.set_name,
                        // maximum_time: e.maximum_time,
                        // no_of_question: e.no_of_question,
                        // set_key: e.set_key,
                        // technology_id: e.technology_id,
                        // technology: e.technology,
                        // technologies: e.technologies,
                        // level_id: e.level_id,
                        // level: e.level && e.level.level_name,
                        // created_by: e.created_by,
                        // created_on: e.created_on,
                        // updated_by: e.updated_by,
                        // updated_on: e.updated_on,
                        // status: e.status
                    })
                })
                if (newArray.length > 0) {
                    // eslint-disable-next-line
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.questionset_List = newArray2 ? newArray2 : action.payload;
            })
            .addCase(getQuestionSetList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //questionSet create
            .addCase(questionSetCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionSetCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionset_Create`;
                state.questionset_Create = action.payload;
            })
            .addCase(questionSetCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //questionSet edit
            .addCase(questionSetEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionSetEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionset_Edit = action.payload;
            })
            .addCase(questionSetEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //questionSet Update
            .addCase(questionSetUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionSetUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionset_Update`;
                state.questionset_Update = action.payload;
            })
            .addCase(questionSetUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questionset archive
            .addCase(questionSetArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionSetArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionset_Archive`;
                state.questionset_Archive = action.payload;
            })
            .addCase(questionSetArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///question store section///
            //all question list
            .addCase(getQuestionList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.question_List = action.payload;
                state.status = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        ...e,
                        technology_id: e.technology_id,
                        technology: e.technology && e.technology.name,
                        question_level: e.question_level && e.question_level.level_name,
                        question_type: e.question_type && e.question_type.question_type,
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.question_List = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getQuestionList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //question create
            .addCase(questionCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_question_Create`;
                state.question_Create = action.payload;
            })
            .addCase(questionCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //question edit
            .addCase(questionEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.question_Edit = action.payload;
            })
            .addCase(questionEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //question Update
            .addCase(questionUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_question_Update`;
                state.question_Update = action.payload;
            })
            .addCase(questionUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///question archive
            .addCase(questionArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_question_Archive`;
                state.question_Archive = action.payload;
            })
            .addCase(questionArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //download sample file Question
            .addCase(downloadQuestionSample.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(downloadQuestionSample.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_download_question_sample`;
                state.download_question_sample = action.payload;
            })
            .addCase(downloadQuestionSample.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //Batch list
            .addCase(batch.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(batch.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_batch_list`;
                state.batch_list = action.payload;
            })
            .addCase(batch.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //Batch list
            .addCase(batchTechnologyOnFilter.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(batchTechnologyOnFilter.fulfilled, (state, action) => {
                const response = {
                    data: {
                        row: action?.payload?.data?.batch_data || [],
                        count: action?.payload?.data?.batch_data?.count || 0,
                    }
                }

                state.status = `${STATUSES.SUCCESS}_batch_list`;
                state.technology_filter = action?.payload;

            })
            .addCase(batchTechnologyOnFilter.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //batch_create
            .addCase(batchCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(batchCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_batch_create`;
                state.batch_created = action.payload;
            })
            .addCase(batchCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //batch-update
            .addCase(batchUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(batchUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_batch_update`;
                state.batch_updated = action.payload;
            })
            .addCase(batchUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //batch-by-id
            .addCase(batchById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(batchById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_batch_by_id`;
                state.batch_from_id = action.payload;
            })
            .addCase(batchById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //import file Questions
            .addCase(importQusetionFile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(importQusetionFile.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_import_question_file`;
                state.import_question_file = action.payload;
            })
            .addCase(importQusetionFile.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // answer option section start
            //create answer options
            .addCase(postAnswerOptions.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postAnswerOptions.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_answer_options_Create`;
                state.answer_options_Create = action.payload;
            })
            .addCase(postAnswerOptions.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get answer options list
            .addCase(getAnswerOptions.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAnswerOptions.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_answer_options_List`;
                state.answer_options_List = action.payload;
            })
            .addCase(getAnswerOptions.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //update answer options 
            .addCase(updateAnswerOptions.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateAnswerOptions.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_answer_options_Update`;
                state.answer_options_Update = action.payload;
            })
            .addCase(updateAnswerOptions.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///answer options archive
            .addCase(deleteAnswerOptions.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(deleteAnswerOptions.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_answer_options_Delete`;
                state.answer_options_Delete = action.payload;
            })
            .addCase(deleteAnswerOptions.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd qualification list
            .addCase(getQualificationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQualificationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.qualification_List = action.payload;
            })
            .addCase(getQualificationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///qualification create
            .addCase(qualificationCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_qualification_Create`;
                state.qualification_Create = action.payload;
            })
            .addCase(qualificationCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///qualification edit
            .addCase(qualificationEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.qualification_Edit = action.payload;
            })
            .addCase(qualificationEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///qualification Update
            .addCase(qualificationUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_qualification_Update`;
                state.qualification_Update = action.payload;
            })
            .addCase(qualificationUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///qualification archive
            .addCase(qualificationArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_qualification_Archive`;
                state.qualification_Archive = action.payload;
            })
            .addCase(qualificationArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



            // schedule list 
            .addCase(scheduleList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(scheduleList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_schedule_List`;
                state.schedule_List = action.payload;
                state.schedules_option = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.schedule_name, value: e.id }));

            })
            .addCase(scheduleList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            // schedule create 
            .addCase(scheduleCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(scheduleCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_schedule_Create`;
                state.schedule_Create = action.payload;
            })
            .addCase(scheduleCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



            // schedule Update 
            .addCase(scheduleUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(scheduleUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_schedule_Update`;
                state.schedule_Update = action.payload;
            })
            .addCase(scheduleUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            // schedule get by id 
            .addCase(scheduleGetById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(scheduleGetById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_schedule_By_Id`;
                state.schedule_By_Id = action.payload;
            })
            .addCase(scheduleGetById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //////////Industry store section/////////
            ///all industry  list
            .addCase(getAllIndustryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllIndustryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_AllList = action.payload;
                state.industryOptions = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.industry_name, value: e.id }));

            })
            .addCase(getAllIndustryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd industry list
            .addCase(getIndustryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndustryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_List = action.payload;
            })
            .addCase(getIndustryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///industry create
            .addCase(industryCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_Create`;
                state.industry_Create = action.payload;
            })
            .addCase(industryCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///industry edit
            .addCase(industryEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_Edit = action.payload;
            })
            .addCase(industryEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///industry Update
            .addCase(industryUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_Update`;
                state.industry_Update = action.payload;
            })
            .addCase(industryUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///industry archive
            .addCase(industryArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_Archive`;
                state.industry_Archive = action.payload;
            })
            .addCase(industryArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //////////Related Skill store section/////////

            ///all related skill  list
            .addCase(getAllRelatedSkillList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllRelatedSkillList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.related_skill_AllList = action.payload;
            })
            .addCase(getAllRelatedSkillList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd related skill list
            .addCase(getRelatedSkillList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getRelatedSkillList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.related_skill_List = action.payload;
            })
            .addCase(getRelatedSkillList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///related skill create
            .addCase(relatedSkillCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(relatedSkillCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_related_skill_Create`;
                state.related_skill_Create = action.payload;
            })
            .addCase(relatedSkillCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///related skill edit
            .addCase(relatedSkillEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(relatedSkillEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.related_skill_Edit = action.payload;
            })
            .addCase(relatedSkillEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///related skill Update
            .addCase(relatedSkillUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(relatedSkillUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_related_skill_Update`;
                state.related_skill_Update = action.payload;
            })
            .addCase(relatedSkillUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///related skill archive
            .addCase(relatedSkillArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(relatedSkillArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_related_skill_Archive`;
                state.related_skill_Archive = action.payload;
            })
            .addCase(relatedSkillArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //---------------------///Industry - Department store///--------------------//

            ///all Industry - Department
            .addCase(getAllIndustryDepartmentList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllIndustryDepartmentList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_department_AllList = action.payload;
            })
            .addCase(getAllIndustryDepartmentList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd Industry - Department
            .addCase(getIndustryDepartmentList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndustryDepartmentList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_department_List = action.payload;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                // state.department_List = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => {
                res.map((e) => {
                    e.department.map((items) => {
                        return newArray.push({

                            id: items.id,
                            label: items.name,
                            value: items.id,
                            name: "department",


                        })
                    })
                });
                state.department_List = newArray
            })
            .addCase(getIndustryDepartmentList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry - Department create
            .addCase(IndustryDepartmentCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepartmentCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_department_Create`;
                state.industry_department_Create = action.payload;
            })
            .addCase(IndustryDepartmentCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry - Department edit
            .addCase(IndustryDepartmentEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepartmentEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_department_Edit = action.payload;
            })
            .addCase(IndustryDepartmentEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry - Department Update
            .addCase(IndustryDepartmentUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepartmentUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_department_Update`;
                state.industry_department_Update = action.payload;
            })
            .addCase(IndustryDepartmentUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry - Department archive
            .addCase(IndustryDepartmentArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepartmentArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_department_Archive`;
                state.industry_department_Archive = action.payload;
            })
            .addCase(IndustryDepartmentArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //------------Industry-Department-Specialisation---------//

            /// IndustryDepartmentSpecialisation all list
            .addCase(getAllIndustryDepSpecList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllIndustryDepSpecList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industryDepartmentSpecialisation_AllList = action.payload;
            })
            .addCase(getAllIndustryDepSpecList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //---------------------/// mapping IndusDepTechkill store///--------------------//

            /// IndusDepTechSkill list
            .addCase(getIndsDepTechSkill.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndsDepTechSkill.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.indsDepTechSkill_List = action.payload;
            })
            .addCase(getIndsDepTechSkill.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///IndusDepTechSkill create
            .addCase(IndsDepTechSkillCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndsDepTechSkillCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_indsDepTechSkill_Create`;
                state.indsDepTechSkill_Create = action.payload;
            })
            .addCase(IndsDepTechSkillCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///IndusDepTechSkill edit
            .addCase(IndsDepTechSkillEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndsDepTechSkillEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_indsDepTechSkill_Edit`;
                state.indsDepTechSkill_Edit = action.payload;
            })
            .addCase(IndsDepTechSkillEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



            /// Industry-Department-Specialisation list
            .addCase(getIndustryDepSpecList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndustryDepSpecList.fulfilled, (state, action) => {
                state.status = !action.payload.data ? STATUSES.SUCCESS : STATUSES.SUCCESS;
                state.error = !action.payload.data ? false : true;
                state.industryDepartmentSpecialisation_List = action.payload;


            })
            .addCase(getIndustryDepSpecList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry-Department-Specialisation create
            .addCase(IndustryDepSpecCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepSpecCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Create`;
                state.industryDepartmentSpecialisation_Create = action.payload;
            })
            .addCase(IndustryDepSpecCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



            ///Industry-Department-Specialisation edit
            .addCase(IndustryDepSpecEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepSpecEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Edit`;
                state.industryDepartmentSpecialisation_Edit = action.payload;
            })
            .addCase(IndustryDepSpecEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            // /IndusDepTechSkill update
            .addCase(IndsDepTechSkillUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndsDepTechSkillUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_indsDepTechSkill_Update`;
                state.indsDepTechSkill_Update = action.payload;
            })
            .addCase(IndsDepTechSkillUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///Industry-Department-Specialisation Update
            .addCase(IndustryDepSpecUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepSpecUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Update`;
                state.industryDepartmentSpecialisation_Update = action.payload;
            })
            .addCase(IndustryDepSpecUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            .addCase(getIndusDepSpecListData.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndusDepSpecListData.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industryDepSpec_List_data = action.payload;
            })
            .addCase(getIndusDepSpecListData.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry-Department-Specialisation archive
            .addCase(IndustryDepSpecArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepSpecArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Archive`;
                state.industryDepartmentSpecialisation_Archive = action.payload;
            })
            .addCase(IndustryDepSpecArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// Test Type list
            .addCase(getTestTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTestTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.test_type_list = action.payload;
            })
            .addCase(getTestTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Test Type create
            .addCase(testTypeCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(testTypeCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_test_type_create`;
                state.test_type_create = action.payload;
            })
            .addCase(testTypeCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
    },
});


export default masterSlice.reducer;