import * as React from 'react';
import "./evaluator.scss"
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { TablePagination, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { ArrowBack } from "@mui/icons-material";
import { getEvaluationExamList, candidateFinalEvalutorSubmit } from '../../redux/features/evaluationSlice';
import { getCandidateExamScheduleList } from '../../redux/features/candidateExamSlice';
import Filter from '../../components/Filter';
import { useMemo } from 'react';
import { getCandidateList } from '../../redux/features/resourceSlice';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import { technologyListOption } from '../../redux/features/optionsSlice';
import CustomeTooltip from '../../components/common-function/CustomeTooltip';

const user = GetLocalstorage.userDetail();
const userId = user && user.id

const PendingEvaluationExamList = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status, error, evaluationExam_List, final_submit_Exam } = useSelector(state => state.evaluationSlice)
  const { candidateNameDropDownList, candidateEmailDropDownList, candidateExamScheduleList } = useSelector(state => state.resource)
  const { technologys } = useSelector(state => state.dropDownOption)

  const [filterQuery, setFilterQuery] = useState(`{"filter":{"evaluator_id":{"value":${userId},"operation":"eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":10}`)

  // const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
  const [reload, setReload] = useState(false)

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [limit, setLimit] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [defaultValue, setDefaultValue] = useState({})

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
      setReload(false)
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    // dispatch(getCandidateExamScheduleList(`{"filter":{"status":{"value":True,"operation": "eq"}}, "order_by":{"column":"-id"},"no_limit":True,}`))
    dispatch(getEvaluationExamList(filterQuery))
    dispatch(getCandidateList(`{"filter":{"status":{"value":True,"operation": "eq"}}, "order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.CANDIDATE_DROUPDOWN_COLUMNS}],"no_limit":True,}`))
    dispatch(technologyListOption())

    // eslint-disable-next-line
  }, [])

  const handleFinalEvaluationSubmit = (rowData) => {
    let examId = rowData.evaluation_candidateexamschedule.id;
    let obj = Object.freeze({
      exam_schedule_id: rowData.evaluation_candidateexamschedule.id,
      evaluator_id: rowData.evaluator.id,
    })
    setReload('final_submit')
    dispatch(candidateFinalEvalutorSubmit(obj))
    // dispatch(getCandidateExamScheduleList(`{"filter":{"status":{"value":True,"operation": "eq"}}, "order_by":{"column":"-id"},"no_limit":True,}`))

  }
  // response handling
  if (reload === "final_submit" && status === `${STATUSES.SUCCESS}_final_submit_Exam` && final_submit_Exam && final_submit_Exam.message) {
    if (final_submit_Exam.status === 200) {
      toast.success(final_submit_Exam.message)
      dispatch(getEvaluationExamList(filterQuery))
    } else {
      toast.error(final_submit_Exam.message)
    }
    setReload(false)
  }


  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getEvaluationExamList(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getEvaluationExamList(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(evaluationExam_List && evaluationExam_List.data && evaluationExam_List.data.rows && evaluationExam_List.data.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // sorting
  const handleSort = (sort) => {
    if (sort.length) {
      let newFilterData = JSON.parse(filterQuery);
      newFilterData['skip'] = 0;
      newFilterData['limit'] = limit;
      newFilterData['order_by'] = {
        "column": sort[0].sort === "desc" ?
          '-' + sort[0].field : sort[0].field
      }
      setFilterQuery(JSON.stringify(newFilterData))
      dispatch(getEvaluationExamList(JSON.stringify(newFilterData)))
    } else {
      setFilterQuery(`{ "filter":{"evaluator_id":{"value":${userId},"operation":"eq"}},"skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
    }
  }

  // const handleViewResult = (id) => {
  //   navigate(`/candidate-result-view/${id}`)
  // }


  const actionColumn = [
    { field: "id", headerName: "S N.", width: 80 },
    {
      field: "evaluation_candidateexamschedule__candidate_id", headerName: "Candidate’s Name", width: 230, type: 'singleSelect', valueOptions: candidateNameDropDownList,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            <CustomeTooltip
              title={params.row.evaluation_candidateexamschedule && params.row.evaluation_candidateexamschedule.candidate && params.row.evaluation_candidateexamschedule.candidate.first_name ? params.row.evaluation_candidateexamschedule.candidate.first_name : 'N/A'}
              data={params.row.evaluation_candidateexamschedule && params.row.evaluation_candidateexamschedule.candidate && params.row.evaluation_candidateexamschedule.candidate.first_name ? params.row.evaluation_candidateexamschedule.candidate.first_name : 'N/A'}
            />
          </div>
        )
      }
    },
    {
      field: "evaluation_candidateexamschedule__candidate", headerName: "Candidate’s Email", width: 230, type: 'singleSelect', valueOptions: candidateEmailDropDownList,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            <CustomeTooltip
              title={params.row.evaluation_candidateexamschedule && params.row.evaluation_candidateexamschedule.candidate && params.row.evaluation_candidateexamschedule.candidate.email ? params.row.evaluation_candidateexamschedule.candidate.email : 'N/A'}
              data={params.row.evaluation_candidateexamschedule && params.row.evaluation_candidateexamschedule.candidate && params.row.evaluation_candidateexamschedule.candidate.email ? params.row.evaluation_candidateexamschedule.candidate.email : 'N/A'}
            />
          </div>
        )
      }
    },
    {
      field: "evaluation_candidateexamschedule__scheduled_date", headerName: "Date of Exam", width: 160, type: "date",
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {params.row.evaluation_candidateexamschedule && params.row.evaluation_candidateexamschedule.scheduled_date ? params.row.evaluation_candidateexamschedule.scheduled_date : 'N/A'}
          </div>
        )
      },
    },
    {
      field: "evaluation_candidateexamschedule__scheduled_time", headerName: "Time of Exam", width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {params.row.evaluation_candidateexamschedule && params.row.evaluation_candidateexamschedule.scheduled_time ? params.row.evaluation_candidateexamschedule.scheduled_time : 'N/A'}
          </div>
        )
      },
    },
    {
      field: "evaluation_candidateexamschedule__technology_id", headerName: "Technology", width: 180,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {params.row.technology && params.row.technology.name ? params.row.technology.name : ''}
          </div>
        )
      }
    },
    {
      field: "total_marks", headerName: "Total mark", width: 150, filterable: false, sortable: false, renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {params.row.total_marks ? params.row.total_marks : ""}
          </div>
        );
      },
    },
    {
      field: "marks_obtained", headerName: "Marks Obtained", width: 150, filterable: false, sortable: false, renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {params.row.marks_obtained ? params.row.marks_obtained : ""}
          </div>
        );
      },
    },
    {
      field: "mark_obtained_percent", headerName: "Percentage", width: 150, filterable: false, sortable: false, renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {params.row.mark_obtained_percent ? params.row.mark_obtained_percent : ""}
          </div>
        );
      },
    },
    {
      field: "is_passed", headerName: "Result Status", width: 150, filterable: false, sortable: false, renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}
          style={params.row.evaluation_status && params.row.marks_obtained ?
            params.row.is_passed === true ? { color: "green" } : { color: "red" } : { color: "#0747a6" }}>
          { ( params.row.evaluation_status && params.row.marks_obtained ?
            params.row.is_passed === true ? " Pass " : " Fail " : "" ) || "Pending"}
          </div>
        );
      },
    },
    {
      field: "status", headerName: "Evaluation Status", width: 190, type: 'singleSelect',
      valueOptions: [{ label: "Active", value: "True" }, { label: "Inactive", value: "False" }],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
                 {params.row.evaluation_status === 1 ? 'Evaluated' : 'In Progress'}
          </div>
        );
      },
    },
    {
      field: "schedule_status",
      headerName: "Schedule Status",
      width: 250,
      type: 'singleSelect',
      valueOptions: GetLocalstorage.schedule_exam_status(),
      renderCell: (params) => {

        return (
              <div className="cellAction" style={params.row.evaluation_candidateexamschedule?.schedule_status
               === 0 ? { color: 'Orange' } :
                  params.row.evaluation_candidateexamschedule?.schedule_status === 1 ? { color: 'Orange' } :
                      params.row.evaluation_candidateexamschedule?.schedule_status === 2 ? { color: '#14d0e4' } :
                          params.row.evaluation_candidateexamschedule?.schedule_status === 3 ? { color: 'Orange' } :
                              params.row.evaluation_candidateexamschedule?.schedule_status === 4 ? { color: 'Red' } :
                                  params.row.evaluation_candidateexamschedule?.schedule_status === 5 ? { color: 'Orange' } :
                                      params.row.evaluation_candidateexamschedule?.schedule_status === 6 ? { color: 'Orange' } :
                                          params.row.evaluation_candidateexamschedule?.schedule_status === 7 ? { color: 'Green' } :
                                              { color: '#000000' }
              }>
                  <p>{params.row.evaluation_candidateexamschedule?.schedule_status === 0 ? "Pending" :
                      params.row.evaluation_candidateexamschedule?.schedule_status === 1 ? "Pending" :
                          params.row.evaluation_candidateexamschedule?.schedule_status === 2 ? "Inprogress" :
                              params.row.evaluation_candidateexamschedule?.schedule_status === 3 ? "Evaluation Pending" :
                                  params.row.evaluation_candidateexamschedule?.schedule_status === 5 ? "Evaluator Assigned" :
                                      params.row.evaluation_candidateexamschedule?.schedule_status === 6 ? "Evaluation Inprogress" :
                                          params.row.evaluation_candidateexamschedule?.schedule_status === 7 ? "Result Declared" :
                                              params.row.evaluation_candidateexamschedule?.schedule_status === 4 ? "Cancelled" :
                                                  "N/A"}</p>

          </div>
        );
      },
    },
    {
      field: "action", headerName: "Action", width: 90, hideable: false, filterable: false, sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              deleteIconName="ArchiveIcon"
              moduleName="technology"
              rowid={params.row.id}
              evaluation_status={params.row.evaluation_status}
              addMoreData=""
              editLinkUrl=""
              viewLinkUrl={`/view-question-details/${params.row.evaluation_candidateexamschedule && params.row.evaluation_candidateexamschedule.id}/${params.row.technology && params.row.technology.id}`} isDelete={false}
              editButton={false}
              viewButton={false}
              deleteButton={false}
              submitButton={params.row.evaluation_status === 1 ? true : false}
              onSubmit={handleFinalEvaluationSubmit}
              submitData={params.row}
            />
          </div>
        );
      },
    },
  ];

  // filter
  const onFilterChange = (filterModel) => {
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
      setFieldName(filterModel.items[0].columnField)
    }
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "equals" ? 'ieq' :
          filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
              filterModel.items[0].operatorValue === "is" ? 'eq' :
                filterModel.items[0].operatorValue;
      let field = filterModel.items[0].columnField === 'resource' ? "resource_id" :
        filterModel.items[0].columnField === 'credentails_head' ? "credentails_head_id" :
          filterModel.items[0].columnField;

      const filterData = `{"filter":{"${[field]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"},"evaluator_id":{"value":${userId},"operation":"eq"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
      setFilterQuery(filterData);
      setCurrentPage(0);
      dispatch(getEvaluationExamList(filterData));

    }
    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0);
      setFilterQuery(`{"filter":{"evaluator_id":{"value":${userId},"operation":"eq"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`);
      dispatch(getEvaluationExamList(`{"filter":{"evaluator_id":{"value":${userId},"operation":"eq"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`));
      setDefaultValue("")
    }
  }

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      fieldName === "evaluation_candidateexamschedule__candidate_id" ?
        <Filter options={candidateNameDropDownList} onChange={handleFilterChange} defaultValue={defaultValue} />
        : fieldName === "evaluation_candidateexamschedule__candidate" ?
          <Filter options={candidateEmailDropDownList} onChange={handleFilterChange} defaultValue={defaultValue} />
          : fieldName === "evaluation_candidateexamschedule__technology_id" ?
            <Filter options={technologys} onChange={handleFilterChange} defaultValue={defaultValue} />
            : ""
    );
  }

  const columns = useMemo(() =>
    actionColumn.map((col) => {
      if (col.field === "id" || col.field === "status" || col.field === "evaluation_candidateexamschedule__scheduled_date" || col.field === "evaluation_candidateexamschedule__scheduled_time") {
        return col;
      }

      return {
        ...col,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
          .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent
              ? CustomFilterInputValue
              : undefined
          }))
      };

      // eslint-disable-next-line
    }), [actionColumn]
  );

  return (
    <div className="master">
      <Sidebar />
      <div className="masterContainer">
        <Navbar />
        <ToastContainer />
        <div className="datatable">
          <div className="top-headings">
            <h3>Evaluation Exam List</h3>
            <div>
              <Tooltip title="Back">
                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
              </Tooltip>
            </div>
          </div>
          {(status === STATUSES.LOADING || reload === true) && <LoaderForm />}
          <DataGrid
            className="datagrid"
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            rows={evaluationExam_List && evaluationExam_List.data && evaluationExam_List.data.rows ? evaluationExam_List.data.rows : []}
            columns={columns}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            disableSelectionOnClick
            components={{
              Pagination: CustomPagination,
            }}
            onSortModelChange={(sort) => handleSort(sort)}
            // onRowClick={(row) => { navigate(`/view-question-details/${row.evaluation_candidateexamschedule && row.evaluation_candidateexamschedule.id}`) }}
            filterMode="server"
          />
        </div>

      </div>

    </div>
  )
}

export default PendingEvaluationExamList