import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { BatchColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, ArrowBack, Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import { CESDateTimePicker, InputFields, InputMultiSelectField, InputRadioGroupFields } from "../../components/common-function/InputFields";
import { batch, batchCreate, batchUpdate, batchById, batchTechnologyOnFilter } from "../../redux/features/masterSlice";
import { technologyListOption } from "../../redux/features/optionsSlice";
import moment from "moment";
import Filter from "../../components/Filter";
import "react-datepicker/dist/react-datepicker.css";
import { getIndustryDepartmentList, getIndustryList, getIndusDepSpecListData } from "../../redux/features/masterSlice";

import { getDepartmentList } from "../../../src/redux/features/departmentSlice";

import Select from 'react-select';


const initialFormData = Object.freeze({
    display_name: "",
    technology: "",
    status: "",
    industry: "",
    department: ""
});

const formErrData = Object.freeze({
    displayNameErr: "Display name is required",
    technologyErr: "Technology is required",
    statusErr: "Status is required",
    industryErr: "Industry is required",
    departmentErr: "Department is required",
});

const Batch = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [technologies, setTechnologies] = useState([])
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, batch_list, batch_created, batch_updated, batch_from_id, technology_filter, industry_List, department_List, industryDepSpec_List_data } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [startDate, setStartDate] = useState()
    const [expiryDate, setExpiryDate] = useState(null)
    const { technology: technologiesList } = useSelector(state => state.dropDownOption)
    const [filterTechnologyList, setFilterTechnologyList] = useState([]);
    const [options, setOptions] = useState({ status: [{ value: "True", label: 'Active' }, { value: "False", label: 'Inactive' }] })
    const [defaultValue, setDefaultValue] = useState({})
    const [fieldName, setFieldName] = useState("")
    const [selectedDate] = useState(null)
    const [expandedID, setExpandedID] = useState()
    const [updatedtechnologyList, setupdatedtechnologyList] = useState([])

    function setAllDateNull() {
        setStartDate(null);
        setExpiryDate(null)
    }

    useEffect(() => {
        if (technology_filter?.data?.batch_data?.length) {
            const batchIds = technology_filter.data.batch_data.map(item => item.batch_id);
            const params = `{"filter":{"id":{"value": [${batchIds}], "operation": "in"}},"skip":0, "limit":${limit}}`
            // setFilterQuery(params)
            dispatch(batch(params))
        }
        // eslint-disable-next-line
    }, [technology_filter])

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    const getRowHeight = (params) => {
        if (params.id === expandedID && params && params.model && Array.isArray(params.model.technology))
            return 20 * params.model.technology.length + 20;
        return 52; // Default row height
    }

    useEffect(() => {
        dispatch(batch(filterQuery));
        dispatch(technologyListOption(`{"filter": { "status": { "value": "True", "operation": "eq" } }, "order_by":{"column":"-id"},"no_limit":True}`))
        // eslint-disable-next-line
        dispatch(getIndustryDepartmentList(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getIndustryList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
        dispatch(getDepartmentList())
        dispatch(getIndusDepSpecListData(`{"filter":{"status":{'value':"True","operation": "eq"}},"no_limit":"True"}`))
    }, [batch_created, batch_updated])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0) }
        setOpen(false);
        setFormValues(initialFormData);
        setAllDateNull();
        setErrStatus(false)
        setupdatedtechnologyList([])
        setTechnologies([])
    }

    const addMoreTechnology = (evt) => {
        let array = []
        setFormValues({ ...formValues, technology: evt })
        evt.map(e => {
            let filterLevel = technologies.filter(x => x.technology_id === e.id)
            return array.push({
                id: filterLevel.length > 0 ? filterLevel[0].id : "",
                technology: e.technology ? e.technology : e.technology_name,
                technology_id: e.technology_id ? e.technology_id : e.id,
            })
        })
        setTimeout(() => {
            setTechnologies(array)
        }, 500);
    }

    if (filterTechnologyList && filterTechnologyList.length === 0 && technologiesList) {
        let array = []
        technologiesList && technologiesList.map(e => { return array.push({ value: e.id, label: e.name }) })
        array.length > 0 && setFilterTechnologyList(array)
        array.length > 0 && setOptions({ ...options, technology_id: array })
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_batch_create` && batch_created && batch_created.message) {
        if (batch_created.status === 200) {
            toast.success(batch_created.message)
            dispatch(batch(filterQuery))
            handleClose()

        } else {
            toast.error(batch_created.message)
        }
        setReload(false)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_batch_update` && batch_updated && batch_updated.message) {
        if (batch_updated.status === 200) {
            toast.success(batch_updated.message)
            handleClose()
            setOpen(false)
        } else {
            toast.error(batch_updated.message)
        }
        setReload(false)
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_batch_by_id` && batch_from_id && batch_from_id.data) {
        let editData = batch_from_id.data
        setID(editData.id)
        setOpen(true)
        setReload(false)

        const { display_name, status, technology, start_date, start_time, end_date, end_time } = { ...editData };
        const technologyEditData = [];
        // const getAllExectingTechnologyId = technologies?.map(each => each.technology_id)

        // if (getAllExectingTechnologyId?.length) {
        //     const allPreSelectedTechnology = technologiesList?.filter(item => getAllExectingTechnologyId.includes(item.id));

        //     if (allPreSelectedTechnology?.length) {
        //         technologyEditData.push(...allPreSelectedTechnology);
        //         let array = []
        //         allPreSelectedTechnology.map(e => {
        //             let filterLevel = technologies.filter(x => x.technology_id === e.id)
        //             return array.push({
        //                 id: filterLevel.length > 0 ? filterLevel[0].id : "",
        //                 technology: e.technology ? e.technology : e.technology_name,
        //                 technology_id: e.technology_id ? e.technology_id : e.id,
        //             })
        //         })
        //         setTimeout(() => {
        //             setTechnologies(array)
        //         }, 500);
        //     }
        // }
        dispatch(getIndustryDepartmentList(`{"filter":{"industry_id":{"value":${editData?.industry?.id},"operation":"eq"}},"no_limit":True}`))

        if (technology && technology.length > 0) {
            technology.map((item) => {
                technologyEditData.push({
                    technology: item.technology_name,
                    technology_id: item.id,
                    label: item.technology_name,
                    value: item.id,
                    id: item.id,
                    name: item.technology_name
                })
            })
            setTimeout(() => {
                setTechnologies(technologyEditData)
            }, 500);
        }
        const speclist = industryDepSpec_List_data?.data?.rows.map((options) => {
            if (editData?.department?.id === options?.department_id) {
                return options?.specialization_data
            } else {
                return [];
            }
        }).flat()
        setupdatedtechnologyList(speclist ? speclist : [])

        setFormValues({
            ...formValues,
            display_name: display_name,
            status: status,
            technology: technologyEditData,
            department: { name: 'department', label: editData?.department?.name, value: editData?.department?.id },
            industry: { name: 'industry', label: editData?.industry?.industry_name, value: editData?.industry?.id }
        })

        const setUpDateTimeOfStartDate = moment(start_date + ' ' + start_time).format();
        const setUpDateTimeOfEndDate = moment(end_date + ' ' + end_time).format();
        setStartDate(new Date(setUpDateTimeOfStartDate));
        setExpiryDate(new Date(setUpDateTimeOfEndDate));
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const changeExpireDate = (evt) => {
        if (expiryDate) {
            let date_1 = new Date(expiryDate)
            let date_2 = new Date(evt)
            if (date_1 < date_2) {
                setStartDate(evt)
                setExpiryDate(null)
                toast.warning('Start Date  is greater than Expiry Date"')
            } else {
                setStartDate(evt)
            }
        } else {
            setStartDate(evt)
        }
    }

    const handleChangeIndustry = (evt) => {
        const { name, value } = evt.target || evt;
        if (evt) {
            if (name === 'industry') {
                dispatch(getIndustryDepartmentList(`{"filter":{"industry_id":{"value":${value},"operation":"eq"}},"no_limit":True}`))
                setFormValues({ ...formValues, [name]: evt, "department": "", technology: "" })
                setupdatedtechnologyList([])
            } else if (name === 'department') {
                const speclist = industryDepSpec_List_data?.data?.rows.map((options) => {
                    if (evt?.value === options?.department_id) {
                        return options?.specialization_data
                    } else {
                        return [];
                    }
                }).flat()
                setupdatedtechnologyList(speclist ? speclist : [])
                setFormValues({ ...formValues, [name]: evt, "technology": "" });
            } else {
                setFormValues({ ...formValues, [name]: value });
            }
        }
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.display_name === "" || (formValues.technology === "" || formValues.technology.length === 0) || formValues.status === "" || formValues.industry === "" || formValues.department === "") {
            setErrStatus(true)
        }
        if (formValues.display_name !== "" && formValues.status !== "" && (formValues.technology !== "" || formValues.technology.length > 0) && formValues.department !== "" && formValues.industry !== "") {
            const ces_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))
            let userId = ces_user.user && ces_user.user.data && ces_user.user.data.id
            let new_array = []
            // eslint-disable-next-line            
            technologies.map(item => {
                new_array.push(
                    item.technology_id
                )
            })

            let start_date = startDate ? moment(new Date(startDate)).format('YYYY-MM-DD') : null
            let start_time = startDate ? moment(new Date(startDate)).format('HH:mm:ss') : null

            let end_date = expiryDate ? moment(new Date(expiryDate)).format('YYYY-MM-DD') : null
            let end_time = expiryDate ? moment(new Date(expiryDate)).format('HH:mm:ss') : null

            if ((start_date === null && end_date === null) || (start_date && end_date)) {
                let addvalues = ({
                    ...formValues,
                    created_by: userId,
                    technologies: new_array,
                    status: formValues.status === 'false' ? false : true,
                    start_date: start_date ? start_date : end_date ? moment().format('YYYY-MM-DD') : null,
                    end_date: end_date ? end_date : null,

                    start_time: start_time,
                    end_time: end_time,
                    batch_code: null,
                    department: formValues?.department?.value ?? undefined,
                    industry: formValues?.industry?.value ?? undefined,
                })
                delete addvalues.technology
                setErrStatus(false)
                setReload("submit")
                if (ID) {
                    dispatch(batchUpdate({ id: ID, data: addvalues }))
                    setAllDateNull();
                } else {
                    dispatch(batchCreate(addvalues))
                    setAllDateNull()
                }
            } else {
                toast.error("Date is required")
            }
        }
    }

    const handleClickEdit = (id) => {
        setReload('edit_data')
        dispatch(batchById(id))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(batch(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(batch(JSON.stringify(newFilterData)))
    }
    
    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(batch_list && batch_list.data && batch_list.data.rows && batch_list.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(batch(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }

    const handleExpansion = (e_id) => {
        if (e_id === expandedID)
            return setExpandedID(undefined);
        setExpandedID(e_id);
    }

    const actionColumn = [
        {
            field: "technology_id", headerName: "Technology", width: 230, type: "singleSelect", valueOptions: filterTechnologyList, sortable: false,
            renderCell: (params) => {
                return (
                    <div style={{ width: '100%' }} >
                        <div className="cellWithImg" style={{ width: '90%', display: 'inline-block' }}>
                            {params.row.technology && params.row.technology.length ?
                                params.row.technology.map((item, index) => {
                                    return (
                                        <p key={index} style={{ margin: 0 }}>
                                            {index === 0 && item.technology_name} {index === 0 && params.row.technology.length > 1 && ","}
                                            {params.row.technology.length > 1 && index === 1 && item.technology_name} {index === 1 && params.row.technology.length > 2 && ","}
                                            {expandedID === params.id && index > 1 ? `${item.technology_name} ${index === params.row.technology.length - 1 ? '' : ','}` : null}

                                        </p>
                                    )
                                })
                                : "N/A"
                            }
                        </div>
                        <div style={{ width: '10%', display: 'inline-block' }}>
                            {(params.row.technology && params.row.technology.length > 2) ?
                                expandedID === params.id ?
                                    <Tooltip title="View less">
                                        <ExpandLess onClick={() => handleExpansion(params.id)} />
                                    </Tooltip> :
                                    <Tooltip title="View more">
                                        <ExpandMore onClick={() => handleExpansion(params.id)} />
                                    </Tooltip>
                                : ""}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="batch-type"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            /*deleteButton={handleArchive}*/ />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {

        const isFilterTechnology = filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].columnField === "technology_id" ? true : false;

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            let filter_column =
                filterModel.items[0].columnField === 'status_id' ? 'status'
                    : filterModel.items[0].columnField === 'technology' ? 'technology__id'
                        : filterModel.items[0].columnField;

            const filterData = `{"filter":{"${filter_column}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            if (isFilterTechnology) {
                dispatch(batchTechnologyOnFilter({ technology_ids: [filterModel.items[0].value] }))
                dispatch(batch("type"))
            } else {
                dispatch(batch(filterData))
            }
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            if (isFilterTechnology) {
                dispatch(batchTechnologyOnFilter({
                    technology_ids: [],
                }))
            } else {
                dispatch(batch(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
            }
            setDefaultValue("")
        }
    };

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }

    const columns = React.useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "display_name" ||
                col.field === "technology__id" ||
                col.field === 'status' || col.field === 'start_date' || col.field === 'end_date' || col.field === 'action') {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                ? CustomFilterInputValue
                                : undefined
                        }))
                };
            }
            return col;
        }),
        // eslint-disable-next-line
        [actionColumn]
    );

    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Batch List</h3>
                        <div>
                            <Tooltip title="Add New Batch">
                                <button onClick={handleOpen} className="btn-add-new"><Add /> </button>
                            </Tooltip>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={batch_list && batch_list.data && batch_list.data.rows ? batch_list.data.rows : []}
                        columns={BatchColumns.concat(columns)}
                        filterMode={"server"}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                        getRowHeight={getRowHeight}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}

                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add New'} Batch</DialogTitle>

                    <DialogContent style={{ height: "600px" }}>
                        <form style={{ height: "400px" }}>

                            <div className="add-new-master-form">
                                <InputFields
                                    label={"Name"}
                                    name="display_name"
                                    defaultValue={formValues.display_name}
                                    type="text"
                                    placeholder="Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.displayNameErr}
                                />
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                                <div className="formInput">
                                    <label >
                                        Select Industry
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Industry'}
                                        onChange={handleChangeIndustry}
                                        defaultValue={formValues.industry}
                                        value={formValues.industry}
                                        isSearchable={true}
                                        name={'industry'}
                                        options={
                                            industry_List && industry_List.data && industry_List.data.rows.map((option) => {
                                                return {
                                                    label: option.industry_name,
                                                    value: option.id,
                                                    name: 'industry'
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                </div>
                                {
                                    formValues.industry ?
                                        <div className="formInput">

                                            <label >
                                                Select Department
                                            </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder={'Select Department'}
                                                name={"department"}
                                                onChange={handleChangeIndustry}
                                                defaultValue={formValues.department}
                                                value={formValues.department}
                                                isSearchable={true}
                                                options={
                                                    department_List && department_List.map((option) => {
                                                        return {
                                                            label: option.label,
                                                            value: option.value,
                                                            name: "department"
                                                        }
                                                    })
                                                }
                                            />
                                            {errStatus === true && formValues && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                        </div>
                                        : <></>
                                }
                                <InputMultiSelectField
                                    divstyle={{ flexBasis: '100%' }}
                                    label={"Technology"}
                                    name={"technology"}
                                    placeholder={"Select Technology"}
                                    onRemove={addMoreTechnology}
                                    onSelect={addMoreTechnology}
                                    defaultValue={formValues.technology && formValues.technology.length === 0 ? "" : formValues.technology}
                                    errStatus={errStatus}
                                    technologyErr={formErr.technologyErr}
                                    optionList={updatedtechnologyList ? updatedtechnologyList : []}
                                    optionLevel={['name']}
                                    optionValue={'id'}
                                />
                                <div className="formInput" style={{ flexBasis: "100%" }}>
                                    {errStatus === true && formValues && (formValues.technology === "" || formValues.technology.length === 0) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.technologyErr}</p>}
                                </div>

                                <CESDateTimePicker
                                    label={'Start Date'}
                                    placeholder="dd-mm-yyyy hh:mm"
                                    selectedDate={startDate}
                                    name={"scheduled_dated"}
                                    onChange={changeExpireDate}
                                    disabledDate={new Date()}
                                />
                                <CESDateTimePicker
                                    label={'Expiry Date'}
                                    placeholder="dd-mm-yyyy hh:mm"
                                    selectedDate={expiryDate}
                                    name={"scheduled_dated"}
                                    onChange={setExpiryDate}
                                    disabledDate={startDate ? new Date(startDate) : new Date()}
                                    value={selectedDate}
                                />

                                {/* <CESDateTimePicker
                                    label={'Expiry Date'}
                                    placeholder="dd-mm-yyyy hh:mm"
                                    selectedDate={expiryDate}
                                    name={"scheduled_dated"}
                                    onChange={changeExpireDate}
                                    disabledDate={startDate ? new Date(startDate) : new Date()}
                                /> */}

                            </div>
                            <button
                                className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                onClick={submitData}
                                disabled={reload === "submit" ? true : false}
                            >
                                {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </div >
    )
}

export default Batch;