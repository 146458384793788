import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./resource.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoaderForm from "../../components/common-function/LoaderForm";
import { resourceCreate, resourceUpdateById } from "../../redux/features/resourceSlice";
import "react-datepicker/dist/react-datepicker.css";
import DataService from "../../redux/services/data.service";
import { toast, ToastContainer } from "react-toastify";
import CONSTANTS from "../../components/constant/constantComponents";
import { STATUSES } from "../../redux/common-status/constant";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Select from 'react-select';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { roleListOption } from "../../redux/features/optionsSlice";
import { getIndustryDepartmentList, getIndustryList, getIndusDepSpecListData } from "../../redux/features/masterSlice";
import { getDepartmentList } from "../../../src/redux/features/departmentSlice";
import { InputMultiSelectField } from "../../components/common-function/InputFields";

const initialFormData = Object.freeze({
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
    tms_role: "",
    password: "",
    industry: "",
    department: "",
    technology: "",
});

const formErrData = Object.freeze({
    f_nameErr: "First name is required",
    l_nameErr: "Last name is required",
    emailErr: 'Email is required',
    contact_noErr: "Contact no is required",
    tms_roleErr: "Role is required",
    passwordErr: "Password is required",
    industryErr: "Industry is reuired",
    departmentErr: "Department is required",
    technologyErr: "Technology is required"
});

const ResourceEdit = ({ resId }) => {
    // const { REACT_APP_ENV } = process.env;
    const dispatch = useDispatch()
    const { resourceid } = useParams()
    const navigate = useNavigate()
    const { view_data, status, error, createResource, updateResource } = useSelector(state => state.resource)
    const { industry_List, department_List, industryDepSpec_List_data } = useSelector(state => state.master)
    const { roles_option } = useSelector(state => state.dropDownOption)

    const [formValues, setFormValues] = useState(initialFormData)
    const [loader, setLoader] = useState(false)
    const [formErr] = useState(formErrData)
    const [errSatus, setErrSatus] = useState(false);
    const [validateErr, setValidateErr] = useState("")
    const [updatedtechnologyList, setupdatedtechnologyList] = useState([])
    // const [errStatus, setErrStatus] = useState(false);
    const [technologies, setTechnologies] = useState([])

    const loadData = async () => {
        const res = await DataService.resource_view(resourceid)
        if (res && res.data && res.data.data) {

            const editData = res.data.data
            setFormValues({
                first_name: editData && editData.first_name,
                last_name: editData && editData.last_name,
                email: editData && editData.email,
                contact_no: editData && editData.contact_no,
                tms_role: { label: editData.tms_role && editData.tms_role.role_name, value: editData && editData.tms_role_id },
                password: editData && editData.password,

                user: editData && editData.user_id,
                industry: {
                    label: editData?.industry?.industry_name ?? "",
                    value: editData?.industry?.id ?? "",
                    name: 'industry'
                },
                department: {
                    label: editData?.department?.name ?? "",
                    value: editData?.department?.id ?? "",
                    name: 'department'
                },
                technology: editData?.candidate_technologies.map((item) => {
                    return {
                        id: item.technology_id,
                        name: item.technology_name,
                        status: true
                    }
                }) ?? []


            })

        }
    }

    const addMoreTechnology = (evt) => {
        let array = []
        setFormValues({ ...formValues, technology: evt })
        evt.map(e => {
            let filterLevel = technologies.filter(x => x.technology_id === e.id)
            return array.push({
                id: filterLevel.length > 0 ? filterLevel[0].id : "",
                technology: e.technology ? e.technology : e.technology_name,
                technology_id: e.technology_id ? e.technology_id : e.id,
            })
        })
        setTimeout(() => {
            setTechnologies(array)
        }, 500);

    }


    useEffect(() => {
        if (resourceid) {
            loadData()
        }
        dispatch(roleListOption())
        if (view_data && view_data.data) {
            setFormValues(loadEditResourceData)

        }

        // eslint-disable-next-line
    }, [dispatch])
    useEffect(() => {
        // eslint-disable-next-line
        dispatch(getIndustryDepartmentList(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getIndustryList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
        dispatch(getDepartmentList())
        dispatch(getIndusDepSpecListData(`{"filter":{"status":{'value':"True","operation": "eq"}},"no_limit":"True"}`))
    }, [])

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value });
    }

    const validate = (email, contact) => {
        const errors = {}

        if (!email) {
            errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Invalid email address'
        } else {
            errors.email = true
        }

        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(contact)) {
            errors.contact = "Please Enter Number Only";
        } else if (contact.length !== 10) {
            errors.contact = "Please enter valid  Mobile Number.";
        } else {
            errors.contact = true
        }
        return errors
    }

    const handleChangeIndustry = (evt) => {
        const { name, value } = evt.target || evt;
        if (evt) {
            if (name === 'industry') {
                // setIndustryId(value)
                dispatch(getIndustryDepartmentList(`{"filter":{"industry_id":{"value":${value},"operation":"eq"}},"no_limit":True}`))
                setFormValues({ ...formValues, [name]: evt, "department": "", technology: "" })
                setupdatedtechnologyList([])
            } else if (name === 'department') {
                const speclist = industryDepSpec_List_data?.data?.rows.map((options) => {
                    if (evt?.value === options?.department_id) {
                        return options?.specialization_data
                    } else {
                        return [];
                    }
                }).flat()
                setupdatedtechnologyList(speclist ? speclist : [])
                // dispatch(getTechnologyList(`{"filter":{"industry_id":{"value":${value},"operation":"eq"}},"no_limit":True}`))
                // dispatch(technologyListOption(`{"filter": { "status": { "value": "True", "operation": "eq" } }, "order_by":{"column":"-id"},"no_limit":True}`))
                setFormValues({ ...formValues, [name]: evt, "technology": "" });
            } else {
                setFormValues({ ...formValues, [name]: value });
            }
        }
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.first_name === "" || formValues.last_name === "" || formValues.email === "" || formValues.tms_role === "" || formValues.password === "" || formValues.tms_role === "" || formValues.industry === "" || formValues.department === "" || formValues.technology === "") {
            setErrSatus(true)
            return
        }

        if (formValues.first_name && formValues.last_name && formValues.email && formValues.password && formValues.contact_no && formValues.tms_role && formValues.industry !== "" && formValues.department !== "" && formValues.technology !== "") {
            const isValidate = validate(formValues.email, formValues.contact_no)

            if (isValidate.email === true && isValidate.contact === true) {
                let addvalues = {
                    ...formValues, first_name: formValues.first_name, last_name: formValues.last_name,
                    tms_role: formValues.tms_role.value ? formValues.tms_role.value : formValues.tms_role,
                    batch_code: null,
                    department: formValues?.department?.value ?? undefined,
                    industry: formValues?.industry?.value ?? undefined,
                    status: true, registration_status: 0, created_by: GetLocalstorage.userDetail().id, resume_file: null,
                    technologies: formValues.technology.map((item) => {
                        return { technology_id: item.id, status: item.status }
                    })
                }
                setErrSatus(false)
                setValidateErr("")
                setLoader(true)
                if (resourceid) {
                    dispatch(resourceUpdateById({ id: resourceid, addvalues: addvalues }))
                } else {
                    dispatch(resourceCreate(addvalues))
                }

            } else {
                setErrSatus(true)
                setValidateErr(isValidate)
            }
        }
    }

    if (loader === true && status === STATUSES.FAILURE && error && error.message) {
        setLoader(false)
        toast.error(error.message);
    }

    if (loader === true && status === `${STATUSES.SUCCESS}_createResource` && createResource && createResource.message) {
        if (createResource.status === 200) {
            toast.success(createResource.message);
            setTimeout(() => {
                navigate('/users')
            }, 1000)
        } else {
            toast.error(createResource.message)
        }
        setLoader(false)
    }

    if (loader === true && status === `${STATUSES.SUCCESS}_updateResource` && updateResource && updateResource.message) {
        if (updateResource.status === 200) {
            toast.success(updateResource.message);
            setTimeout(() => {
                // loadData()
                navigate('/users')
            }, 2000)
        } else {
            toast.error(updateResource.message)
        }
        setLoader(false)
    }

    const goback = () => {
        if (resId) {
            navigate('/profile')
        } else {
            navigate('/users')
        }
    }

    const loadEditResourceData = () => {
        const data = view_data.data
        const editFormValues = {
            first_name: data && data.first_name,
            last_name: data && data.last_name,
            email: data && data.email,
            contact_no: data && data.contact_no,
            tms_role: data && data.tms_role && data.tms_role.id,
            password: data && data.password,
            industry: "",
        }
        return editFormValues
    }

    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>{resId ? "Edit Profile" : resourceid ? "Edit user" : "Add  user"}</h3>

                    <Tooltip title="Back">
                        <button onClick={goback} className="btn btn-add-new"><ArrowBackIcon /></button>
                    </Tooltip>
                </div>
                <ToastContainer />
                {loader === true && <LoaderForm />}
                <div>
                    {(formValues.email || !resourceid) ?
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label >
                                        First Name
                                    </label>
                                    <input
                                        name="first_name"
                                        defaultValue={formValues.first_name}
                                        type="text"
                                        placeholder="First Name"
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.first_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.f_nameErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >
                                        Last Name
                                    </label>
                                    <input
                                        name="last_name"
                                        defaultValue={formValues.last_name}
                                        type="text"
                                        placeholder="Last Name"
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.last_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.l_nameErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >
                                        Email
                                    </label>
                                    <input
                                        name="email"
                                        type="email"
                                        defaultValue={formValues.email}
                                        placeholder="Email"
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.email === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.emailErr}</p>}
                                    {errSatus === true && validateErr && validateErr.email !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.email}</p>}
                                </div>

                                <div className="formInput">
                                    <label >
                                        Contact No
                                    </label>
                                    <input
                                        name="contact_no"
                                        defaultValue={formValues.contact_no}
                                        type="text"
                                        placeholder="Contact No"
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.contact_no === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.contact_noErr}</p>}
                                    {errSatus === true && validateErr && validateErr.contact !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.contact}</p>}
                                </div>

                                <div className="formInput">
                                    <label >
                                        Select Industry
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Industry'}
                                        onChange={handleChangeIndustry}

                                        defaultValue={formValues.industry}
                                        value={formValues.industry}
                                        isSearchable={true}
                                        name={'industry'}
                                        options={
                                            industry_List && industry_List.data && industry_List.data.rows.map((option) => {
                                                return {
                                                    label: option.industry_name,
                                                    value: option.id,
                                                    name: 'industry'
                                                }
                                            })
                                        }
                                    />
                                    {errSatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                </div>
                                {
                                    formValues.industry ?
                                        <div className="formInput">
                                            <label >
                                                Select Department
                                            </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                // divstyle={{ flexBasis: '50%' }}
                                                placeholder={'Select Department'}
                                                name={"department"}
                                                onChange={handleChangeIndustry}
                                                defaultValue={formValues.department}
                                                value={formValues.department}
                                                isSearchable={true}
                                                options={
                                                    department_List && department_List.map((option) => {
                                                        return {
                                                            label: option.label,
                                                            value: option.value,
                                                            name: "department"
                                                        }
                                                    })

                                                }
                                            />
                                            {errSatus === true && formValues && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                        </div>
                                        : <></>
                                }
                                <InputMultiSelectField
                                    divstyle={{ flexBasis: '50%' }}
                                    label={"Technology"}
                                    name={"technology"}
                                    placeholder={"Select Technology"}
                                    onRemove={addMoreTechnology}
                                    onSelect={addMoreTechnology}
                                    defaultValue={formValues.technology && formValues.technology.length === 0 ? "" : formValues.technology}
                                    errStatus={errSatus}
                                    formErr={formErr.technologyErr}
                                    optionList={updatedtechnologyList ? updatedtechnologyList : []}
                                    optionLevel={['name']}
                                    optionValue={'id'}
                                />
                                <div className="formInput">
                                    <label >
                                        Role
                                    </label>
                                    <Select
                                        className="select"
                                        classNamePrefix="select"
                                        placeholder="Select"
                                        defaultValue={formValues.tms_role}
                                        isSearchable={true}
                                        name={"tms_role"}
                                        options={
                                            roles_option && roles_option.map((option) => {
                                                return { label: option.label, value: option.value, name: "tms_role" }
                                            })
                                        }
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.tms_role === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.tms_roleErr}</p>}
                                </div>

                                <div className="formInput">
                                    <label >
                                        Password
                                    </label>

                                    <input
                                        name="password"
                                        defaultValue={formValues.password}
                                        type="password"
                                        placeholder="password"
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.password === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.passwordErr}</p>}

                                </div>
                            </div>
                            {/* {error && <p style={{ color: "#96332c", fontSize: 13 }}> {error.message}</p>} */}
                            <button onClick={submitData} className="btn btn-submit">{resourceid ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                        : ""}
                </div>
            </div>
        </div>
    )
}
export default ResourceEdit