import "./manageuser.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useEffect, useState } from "react";
import dataService from "../../redux/services/data.service";
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from "../../components/common-function/LoaderForm";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InputFields } from "../../components/common-function/InputFields";

const formErrData = Object.freeze({
    nameErr: 'User type name is required',
    keyErr: "User type key is required",
    statusErr: "Status is required",
});

const initialFormData = Object.freeze({
    type_name: "",
    type_key: "",
    status: ""
});

const AddManageUser = ({ title }) => {

    const { id } = useParams()
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false)
    const [formData, setFormData] = useState(initialFormData)
    const [formErrors, setFormErrors] = useState(formErrData);
    const [errStatus, setErrStatus] = useState(false);

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setFormData({ ...formData, [name]: value });
    }

    const loadDataById = async () => {
        setShowLoader(true)
        const response = await dataService.userTypeById(id).catch(error => {
            setShowLoader(false)
            return error;
        })
        if (response && response.data && response.data.data) {
            setFormData(response.data.data)
            setShowLoader(false)
        } else {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        id && loadDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const request = { ...formData, created_by: LocalStorageDataService.userDetail().id, }
        if (!formData.type_name || !formData.type_key || formData.status === "") {
            setErrStatus(true)
        }
        if (formData.type_name !== "" && formData.type_key !== "" && formData.status !== "") {
            setErrStatus(false)
            setFormErrors("")

            const response = !id ? await dataService.addUserType(request).catch(error => {
                setShowLoader(false)
                return error;
            }) : await dataService.updateUserType(id, request)

            if (response && response.data && response.data.status === 200) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate('/all-user')
                }, 800)
            } else {
                toast.error(response.data.message);
            }
        }
    }

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>{title} </h3>
                    <Tooltip title="Back">
                        <Link to={"/all-user"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                <ToastContainer />

                {showLoader ? <LoaderForm />
                    : <form onSubmit={handleSubmit}>
                        <div className="add-new-user-form">

                            <div className="formInput">
                                <InputFields
                                    label={'User Type Name'}
                                    name="type_name"
                                    defaultValue={formData.type_name}
                                    type="text"
                                    placeholder="User type name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formValues={formData}
                                    formErr={formErrors.nameErr}
                                />
                            </div>

                            <div className="formInput">
                                <InputFields
                                    label={'User Type Key'}
                                    name="type_key"
                                    defaultValue={formData.type_key}
                                    type="text"
                                    placeholder="User type key"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formValues={formData}
                                    formErr={formErrors.keyErr}
                                />
                            </div>

                            <div className="formInput">
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="status"
                                        value={formData.status}
                                        onChange={(e) => { handleChange(e) }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Active" />
                                        <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                    {errStatus === true && formData && formErrors && formErrors.statusErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formData.status === "" ? formErrors.statusErr : ""}</p>}
                                </FormControl>
                            </div>

                        </div>
                        <button className="btn btn-submit">Submit</button>

                    </form>
                }
            </div>
        </div>
    )
}

export default AddManageUser