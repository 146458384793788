import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";
const { REACT_APP_ENV } = process.env;
const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_CES`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))

export const allResources = createAsyncThunk("resources/list", async (data) => {
    const res = await dataService.resource_list(data);
    const resp = res.data;
    return resp.data;
});

export const allShift = createAsyncThunk("shifts/list", async (data) => {
    const res = await dataService.shiftList(data);
    const resp = res.data;
    return resp.data;
});

export const allReport = createAsyncThunk("reports/list", async (data) => {
    const res = await dataService.resource_list(data);
    const resp = res.data;
    return resp.data;
});

export const allProjects = createAsyncThunk("projects/list", async () => {
    const res = await dataService.project_list();
    const resp = res.data;
    return resp.data;
});

export const allTask = createAsyncThunk("tasks/list", async () => {
    const res = await dataService.tasksList(rtm_user);
    const resp = res.data;
    return resp.data;
});

export const myProjects = createAsyncThunk("myprojects/list", async () => {
    const res = await dataService.myProjects_list(rtm_user);
    const resp = res.data;
    return resp.data;
});

const dashboardsSlice = createSlice({
    name: "dashboardData",
    initialState: {

        resourceList: [],
        projectList: [],
        shiftList: [],
        reportList: [],
        taskList: [],
        myProjectList:[],
        status: STATUSES.IDLE,
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get all Resources
            .addCase(allResources.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(allResources.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.resourceList = action.payload;
                state.error = action.error
            })
            .addCase(allResources.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get all shift
            .addCase(allShift.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(allShift.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.shiftList = action.payload;
                state.error = action.error
            })
            .addCase(allShift.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get all report
            .addCase(allReport.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(allReport.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.reportList = action.payload;
                state.error = action.error
            })
            .addCase(allReport.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get all project
            .addCase(allProjects.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(allProjects.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.projectList = action.payload;
                state.error = action.error
            })
            .addCase(allProjects.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // get all task
            .addCase(allTask.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(allTask.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.taskList = action.payload;
                state.error = action.error
            })
            .addCase(allTask.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get my Project
            .addCase(myProjects.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(myProjects.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.myProjectList = action.payload;
                state.error = action.error
            })
            .addCase(myProjects.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })
    },
});


export default dashboardsSlice.reducer;