import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./master.scss";
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {

    IndustryDepSpecCreate,
    IndustryDepSpecEdit,
    IndustryDepSpecUpdate,
    getIndustryDepSpecList,
    getIndustryList,
    // getSpecialisationsList,
    getIndustryDepartmentList,
    getTechnologyList
    
} from "../../redux/features/masterSlice";
import { getDepartmentList } from "../../../src/redux/features/departmentSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import {
    InputMultiSelectField,
     InputRadioGroupFields,
} from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
// import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
// import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from 'react-select';
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
// import { departmentTypeList } from "../../redux/features/resourceSlice";

const initialFormData = Object.freeze({
    industry: "",
    department: "",
    technolgy: "",
    status: "",
});


const formErrData = Object.freeze({
    industryErr: "Industry is required",
    departmentErr: "department is required",
    specialisationsErr: "specialisations is required",
    statusErr: "Status is required",
});

const IndustryDepSpecialization = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData);
    const [formErr] = useState(formErrData);
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0);
    const [archiveOpen, setArchiveOpen] = useState(false);
    // const [updatedDepList, setUpdatedDepList] = useState([])
    // const [industryId, setIndustyId] = useState(null)

    const {
        status,
        error,
        industryDepartmentSpecialisation_List,
        industryDepartmentSpecialisation_Create,
        industryDepartmentSpecialisation_Edit,
        industryDepartmentSpecialisation_Update,
        industry_List,
        specialisations_option_List,
        department_List,
        technology_List
    


    } = useSelector((state) => state.master);
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false);

    const [filterQuery, setFilterQuery] = useState(
        `{"order_by":{"column":"-id"},"skip":0, "limit":25}`
    );
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        id: false,
    });
    const [changeStatus, setChangeStatus] = useState("");

    useEffect(() => {   
        dispatch(getIndustryDepSpecList(filterQuery));
        dispatch(getIndustryList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
        dispatch(getDepartmentList())
        // dispatch(getSpecialisationsList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
        dispatch(getIndustryDepartmentList(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getTechnologyList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))

        if (status === STATUSES.FAILURE && error) {
            setReload(false);
            errorHandling(error, navigate);
        }
        // eslint-disable-next-line
    }, []);

    if (
        reload === true &&
        status === `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Update` &&
        industryDepartmentSpecialisation_Update &&
        industryDepartmentSpecialisation_Update.message
    ) {
        if (industryDepartmentSpecialisation_Update.status === 200) {
            toast.success(industryDepartmentSpecialisation_Update.message);
            dispatch(getIndustryDepSpecList(filterQuery));
        } else {
            toast.error(industryDepartmentSpecialisation_Update.message);
        }
        setReload(false);
    }

    if (
        reload === true &&
        status === `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Create` &&
        industryDepartmentSpecialisation_Create &&
        industryDepartmentSpecialisation_Create.message
    ) {
        if (industryDepartmentSpecialisation_Create.status === 200) {
            toast.success(industryDepartmentSpecialisation_Create.message);
            dispatch(getIndustryDepSpecList(filterQuery));
        } else {
            toast.error(industryDepartmentSpecialisation_Create.message);
        }
        setReload(false);
    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) {
            setID(0);
            setFormValues(initialFormData);
        }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false);
    };

    const handleChangeOntechnology = (evt) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, "specialization": evt });
        } else {
            setFormValues({ ...formValues, "specialization": "" });
        }
    }
    const handleChange = (evt) =>{
  
        const {name, value} = evt.target || evt ;
        if (evt) {
        if(name === 'industry'){
      
        // setIndustryId(value)
        dispatch(getIndustryDepartmentList(`{"filter":{"industry_id":{"value":${value},"operation":"eq"}},"no_limit":True}`))
        setFormValues({...formValues, [name]:evt,})

        }

        else if (name === 'department') {
            setFormValues({ ...formValues, [name]: evt });

        }  else {
            setFormValues({ ...formValues, [name]: value });
        } 

        }}
    const submitData = (e) => {
        e.preventDefault();

        if (
            formValues.industry === "" ||
            formValues.department === "" ||
            formValues.specialization === "" ||
            formValues.status === ""
        ) {
            setErrStatus(true);
        }
        let specArray = []
        if (formValues.specialization && formValues.specialization.length > 0) {
            formValues.specialization.map(e => e.value ? specArray.push(e.value) : specArray.push(e))
        }
        if (
            formValues.industry !== "" &&
            formValues.department !== "" &&
            formValues.specialization !== "" &&
            formValues.status !== ""
        ) {
            const rtm_user = JSON.parse(
                localStorage.getItem(`${REACT_APP_ENV}_CES`)
            );
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id;
            let addvalues = { ...formValues, created_by:
                 userId,
                 industry: formValues.industry && formValues.industry.label ? formValues.industry.value : formValues.industry,
                 department: formValues.department && formValues.department.label ? formValues.department.value : formValues.department,
                 specialization:specArray
              
                 };

            setErrStatus(false);
            setReload(true);
            handleClose();
            if (ID) {
                dispatch(IndustryDepSpecUpdate({ ID, addvalues }));
            } else {
                dispatch(IndustryDepSpecCreate(addvalues));
            }
        }
    };

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Edit` && industryDepartmentSpecialisation_Edit && industryDepartmentSpecialisation_Edit.data) {
        let editData = industryDepartmentSpecialisation_Edit.data 
        let array1 = []
        if (editData ){

        

        if (editData.specialization_data) {   
              editData.specialization_data.length > 0 && editData.specialization_data.map(e => {
                    return array1.push({
                        label: e.name,
                        value: e.id
                    })
                })

        }
        setFormValues({
            
            industry: {
                label: editData.industry?.industry_name,
                value: editData.industry?.id,
                name: 'industry'
            },
            department: {
                label: editData?.department?.name,
                value: editData?.department?.id,
                name: "department"
            },
            specialization: array1,

            status : editData.status

        })
        setID(editData.id)}
        setOpen(true)
        setReload(false)
    
    }
    
    const handleClickEdit = (id) => {
        dispatch(IndustryDepSpecEdit(id));
        setReload("edit_data");
    };

    const handleArchive = (id) => {
        setArchiveId(id);
        setArchiveOpen(true);
        if (id.status === true) {
            setChangeStatus("InActive");
        } else {
            setChangeStatus("Active");
        }
    };

    const handleClickArchive = (data) => {
        setReload(true);
        setArchiveOpen(false);
        let addvalues = {
            industry: data.industry_id,
            department: data.department_id,
            specialization: data.specialization,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id,
        };
        let ID = data.id;
        setReload(true);
        dispatch(IndustryDepSpecUpdate({ ID, addvalues }));
    };

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData["skip"] = data * pageSize;
        newFilterData["limit"] = limit;
        setLimit(limit);
        setFilterQuery(JSON.stringify(newFilterData));
        dispatch(getIndustryDepSpecList(JSON.stringify(newFilterData)));
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value);
        setCurrentPage(0);
        setLimit(newPageSize.target.value);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData["skip"] = 0;
        newFilterData["limit"] = newPageSize.target.value;
        setFilterQuery(JSON.stringify(newFilterData));
        dispatch(getIndustryDepSpecList(JSON.stringify(newFilterData)));
    };

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={
                    (industryDepartmentSpecialisation_List &&
                        industryDepartmentSpecialisation_List.data &&
                        industryDepartmentSpecialisation_List.data.rows &&
                        industryDepartmentSpecialisation_List.data.count) ||
                    0
                }
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) => (
                    <TablePaginationActions
                        {...subProps}
                        handlePagination={handlePagination}
                    />
                )}
            />
        );
    }
    const getUnique = (arr, index) => {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData["skip"] = 0;
            newFilterData["limit"] = limit;
            newFilterData["order_by"] = {
                column: sort[0].sort === "desc" ? "-" + sort[0].field : sort[0].field,
            };
            setFilterQuery(JSON.stringify(newFilterData));
            dispatch(getIndustryDepSpecList(JSON.stringify(newFilterData)));
        } else {
            setFilterQuery(
                `{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" }}`
            );
        }
    };

    const actionColumn = [
        {
            field: "industry__industry_name",
            headerName: "Industry Name",
            width: 280,
            renderCell: (params) => {
                return <div>{params.row.industry.industry_name}
                </div>;

            },
        },

        {
            field: "department__name",
            headerName: "Department Name",
            width: 280,
            renderCell: (params) => {
                return <div>{params.row.department.name}</div>;
            },
        },
        {
            field: "specialization",
            headerName: "Technology",
            width: 280,
            renderCell: (params) => {
                return <div>
                    {params.row.specialization_data.name}

                    <CustomeTooltip
                        title={params.row.specialization_data && params.row.specialization_data.length > 0 ? getUnique(params.row.specialization_data, 'id').map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "-"}
                        data={params.row.specialization_data && params.row.specialization_data.length > 0 ? getUnique(params.row.specialization_data, 'id').map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "-"}
                    />

                </div>;
            },

        },

        {
            field: "status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? "Active" : "Inactive"}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="specialization"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)}
                        />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (
            filterModel.items &&
            filterModel.items.length > 0 &&
            filterModel.items[0].columnField &&
            filterModel.items[0].value
        ) {
            let operatorValue =
                filterModel.items[0].operatorValue === "contains"
                    ? "icontains"
                    : filterModel.items[0].operatorValue === "startsWith"
                        ? "istartswith"
                        : filterModel.items[0].operatorValue === "endsWith"
                            ? "iendswith"
                            : filterModel.items[0].operatorValue;
            const filterData = `{"filter":{"${[
                filterModel.items[0].columnField,
            ]}":{"value":"${filterModel.items[0].value
                }","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`;
            setFilterQuery(filterData);
            setCurrentPage(0);
            dispatch(getIndustryDepSpecList(filterData));
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0);
            //setPageSize(10)
            setFilterQuery(
                `{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            );
            dispatch(
                getIndustryDepSpecList(
                    `{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`
                )
            );
        }
    };




    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Industry Department Technology List</h3>
                        <div>
                            <Tooltip title="Industry Department Specialisations">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton
                                api={getIndustryDepSpecList}
                                filter={filterQuery}
                                style={{ marginTop: 0 }}
                            />
                        </div>
                    </div>
                    {(status === STATUSES.LOADING ||
                        reload === true ||
                        reload === "edit_data") && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={
                            industryDepartmentSpecialisation_List &&
                                industryDepartmentSpecialisation_List.data &&
                                industryDepartmentSpecialisation_List.data.rows
                                ? industryDepartmentSpecialisation_List.data.rows
                                : []
                        }
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            // NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                    >
                        <Close />
                    </Button>
                    <DialogTitle
                        style={{
                            background: "#2196f3",
                            color: "#fff",
                            marginBottom: "20px",
                        }}
                    >
                        {ID ? "Edit" : "Add"} Industry Department Specialisations
                    </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-master-form">
                                <div className="formInput">
                                    <label >
                                        Select Industry
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Industry'}
                                        onChange={handleChange}
           
                                        defaultValue={formValues.industry}
                                        value={formValues.industry}
                                        isSearchable={true}
                                        name={'industry'}
                                        options={
                                            industry_List && industry_List.data && industry_List.data.rows.map((option) => {
                                                return {
                                                    label: option.industry_name,
                                                    value: option.id,
                                                    name: 'industry'
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                </div>

                                    {
                                    formValues.industry ?
                                        <div className="formInput">

                                            <label >
                                                Select Department
                                            </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                // divstyle={{ flexBasis: '50%' }}
                                                placeholder={'Select Department'}
                                                name= {"department"}
                                                onChange={handleChange}
                                                defaultValue={formValues.department}
                                                value={formValues.department}
                                                isSearchable={true}
                                                options={
                                                      department_List && department_List.map((option) => {
                                                        
                                                            return{
                                                                label: option.label,
                                                                value: option.value,
                                                                name:"department"
                                                            }
                                                        })
                                                        

                                                  
                                                }
                                            />
                                            {errStatus === true && formValues && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                        </div>
                                        : <></>
                                }
                                    
                                    {
                                        formValues.department ?
                                                <div className="formInput">
                                    {/* <InputMultiSelectField
                                        divstyle={{ flexBasis: '100%' }}
                                        label={"Technology"}
                                        name={"specialization"}
                                        placeholder={"Technology"}
                                        // onSelect={handleSkillChange}
                                        // onRemove={handleSkillChange}
                                        defaultValue={formValues.specialization}
                                        // errStatus={errStatus}
                                        formErr={formErr.technologyErr}
                                        optionList={technology_List && technology_List?.data && technology_List?.data?.rows ? technology_List?.data?.rows : []}
                                        optionLevel={['name']}
                                        optionValue={'id'}
                                        onChange={handleChangeOntechnology}
                                        // onChange={handleChange}
                                    /> */}
                                                <label >
                                                    Specialization
                                                </label>
                                                <Select
                                                    isMulti
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    placeholder="Select Technology"
                                                    defaultValue={formValues.specialization}
                                                    // value={formValues.department}
                                                    isSearchable={true}
                                                    name={"specialization"}
                                                    options={
                                                        technology_List && technology_List?.data && technology_List?.data?.rows.map((option) => {
                                                            return { label: option.name, value: option.id, name: "specialization" }
                                                        })
                                                    }
                                                    // options={technology_List && technology_List?.data && technology_List?.data?.rows ? technology_List?.data?.rows : []}

                                                    onChange={handleChangeOntechnology}
                                                // onChange={handleChange}
                                                />
                                                {errStatus === true && formValues && formValues.specialization === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.technology}</p>}
                                            </div>
                                            : <></>
                                    }

                                    <InputRadioGroupFields
                                        label={"Status"}
                                        name="status"
                                        defaultValue={formValues.status}
                                        type="text"
                                        placeholder="Status"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.statusErr}
                                    />
                                </div>
                                <button className="submit-modal" onClick={submitData}>
                                    {ID
                                        ? CONSTANTS.COMMON_BUTTONS.UPDATE
                                        : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                                </button>
                            {/* </div> */}

                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Add`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div>
    );
};

export default IndustryDepSpecialization;
